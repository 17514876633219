import Config from '../classes/Config';
import PlayGame from './PlayGame';
import GameButton from '../classes/GameButton';
import PopInText from '../classes/PopInText';
import _ from 'lodash';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';

export default class GameOptions extends Phaser.Scene {
  constructor() {
    super({
      key: 'GameOptions'
    });
  }

  selectedBorderColor = '0xffca3a'

  panels = {}
  buttons = {}

  getWidth() {
    return this.cameras.main.width;
  }

  getHeight() {
    return this.cameras.main.height;
  }

  preload() {}

  create() { // beginning of game where everything is populated
    let self = this;
    self.game.dnrConfig = new Config();
    this.input.topOnly = true;
    // background graphic
    let background = new Phaser.GameObjects.Graphics(this);
    background.fillStyle(0x00a6b1, 1); // 0x5c3709 // 0x3e1baf
    background.fillRoundedRect(0, 0, this.getWidth(), this.getHeight(), 3);
    background.setAlpha(.9);
    // background.lineStyle(1, 0x4682B4, 1);
    this.add.existing(background);
    // set interactive on the background so you can't click on the game scene behind this one (this might fix it)
    background.setInteractive(new Phaser.Geom.Rectangle(0,0,this.getWidth(),this.getHeight()), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{});

    ////// buttons
    let xChain = 50;
    let yChain = 50;
    this.buttons.general = new GameButton({scene: this,
      x: xChain,
      y: yChain,
      w: 400,
      h: 180,
      depth: this.depth + 1,
      text: `General`,
      font: "55px Arial",
      radius: 32,
      clickFunction: ()=>{
        // load general view
        this.switchToPanel(['difficulty', 'initial cards dealt', 'deck reshuffles']);
        this.buttons.general.select();
      },
      clickFunctionData: {},
      borderWidth: 6,
    });
    this.buttons.general.select();

    this.buttons.close = new GameButton({scene: this,
      x: this.getWidth() - 140,
      y: yChain,
      w: 90,
      h: 90,
      depth: this.depth + 1,
      text: `✖`,
      font: "55px Arial",
      radius: 32,
      clickFunction: ()=>{
        this.input.topOnly = true;
        this.loadingMessage && this.loadingMessage.destroy();
        this.scene.pause('GameOptions');
        this.scene.setVisible(false, 'GameOptions');
        this.scene.stop('GameOptions'); //.remove('GameOptions');
      },
      clickFunctionData: {},
      borderWidth: 6,
    });

    ////// difficulty
    this.createTable({
      scene: this, data: PlayGame.gameDifficulties, key: 'difficulty',  height: 350, textKey: 'level', fileKey: 'file', loadCallback: this.difficultyLoadCallback,
      clickCallback: this.difficultyClickCallback, showSlider: false, width: 1, showTitle: true
    }); // take half of menu with width: this.getWidth() / 2 - 75 // use width: 1 for minimum width based on contents (usually works fine)

    ////// numCardsDealt
    this.createTable({
      scene: this, data: [], key: 'initial cards dealt',  height: 350, loadCallback: ()=>{},
      clickCallback: this.initialCardsDealtClickCallback, showSlider: false, width: 350, showTitle: true, x: this.getWidth() / 2,
      singleValue: this.scene.get('PlayGame').gameInitialCardsDealt
    }); // take half of menu with width: this.getWidth() / 2 - 75 // use width: 1 for minimum width based on contents (usually works fine)

    ////// deckReshuffles
    this.createTable({
      scene: this, data: [], key: 'deck reshuffles',  height: 350, loadCallback: ()=>{},
      clickCallback: this.deckReshufflesClickCallback, showSlider: false, width: 350, showTitle: true, x: this.getWidth() * .75,
      singleValue: this.scene.get('PlayGame').gameDeckReshuffles
    });

    ////// TODO what to call it when no players discard for a round (round being when it gets back to the player who started the round)
    // player can discard any card
    // player can discard just requiring one attribute match
    // draw pile auto plays card to discard pile
  }

  switchToPanel(keys) {
    for (const k in this.panels) {
      if (keys.indexOf(k) > -1) {
        this.panels[k].setVisible(true);
      } else {
        // console.log('switchToPanel hiding:', k)
        this.panels[k].setVisible(false);
      }
    };
    for (const k in this.buttons) {
      if (keys.indexOf(k) > -1) {
      } else {
        // console.log('switchToPanel hiding:', k)
        this.buttons[k] && this.buttons[k].unselect();
      }
    };
  }

  ////// difficulty
  difficultyLoadCallback(scene, label) {
    if (label.name == this.game.scene.keys.PlayGame.gameDifficulty.level) {
      this.selectDifficultyChild(label);
    }
  }
  difficultyClickCallback(child) {
    this.game.dnrConfig.set("gameDifficulty", child.name);
    this.game.scene.keys.PlayGame.setGameDifficulty(child.name);
    this.selectDifficultyChild(child);
    let gameDifficultyObj = PlayGame.gameDifficulties.find(obj => {
      return obj.level === child.name;
    });
    clearTimeout(this.difficultyPitTimeout);
    if (this.difficultyPit) {
      this.difficultyPit.destroy();
    }
    this.difficultyPit = new PopInText({
      scene: this,
      x: this.game.config.width / 2,
      y: this.game.config.height / 2,
      text: `${child.name} difficulty\n` +
            `${gameDifficultyObj.pointsPerCard}x points per card played\n` +
            `${gameDifficultyObj.pointsPerWin} points per win`,
      box: {w: 1050, h: 350, radius: 30},
      startScale: 1,
      endScale: 1,
      scaleBy: 1.25,
      duration: 200,
      onComplete: () => {
        this.difficultyPitTimeout = this.scene.time.delayedCall(3000, () => {
          this.difficultyPit.destroy();
        });
      },
      font: "66px Arial Black",
    })
  }
  selectDifficultyChild(child) {
    if (this.selectedDifficulty && this.selectedDifficulty.getElement("background")) {
      this.selectedDifficulty.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedDifficulty = child;
  }

  ////// InitialCardsDealt
  initialCardsDealtClickCallback(child) {
    // console.log(`ICD:`, child);
    // below is playing around with action sheet (list-style of options. moto phone only gave me 5 max though)
    // https://capacitorjs.com/docs/apis/action-sheet
    ((async () => await ActionSheet.showActions({
      title: 'Select number of initial cards dealt',
      // message: 'Select number of initial cards dealt', // only supported on iOS
      options: PlayGame.initialCardsDealtOptions,
    }))().then((v)=>{
      // console.log(v);
      // this.game.dnrConfig.set("gameInitialCardsDealt", child.name); // NOT using for now as we don't persist this option
      this.initialCardsDealtTextObj.setText(PlayGame.initialCardsDealtOptions[v.index].title);
      this.game.dnrConfig.set("gameInitialCardsDealt", PlayGame.initialCardsDealtOptions[v.index].title);
      this.game.scene.keys.PlayGame.setGameInitialCardsDealt(PlayGame.initialCardsDealtOptions[v.index].title);
    }));
  }

  ////// DeckReshuffles
  deckReshufflesClickCallback(child) {
    // console.log(`DR:`, child);
    // below is playing around with action sheet (list-style of options. moto phone only gave me 5 max though)
    // https://capacitorjs.com/docs/apis/action-sheet
    ((async () => await ActionSheet.showActions({
      title: 'Select number of deck reshuffles',
      options: PlayGame.deckReshufflesOptions,
    }))().then((v)=>{
      // console.log(v);
      const val = PlayGame.deckReshufflesOptions[v.index].title;
      if (Number(val)) { // if it's an actual number
        this.deckReshufflesTextObj.style.setFontSize(110);
      }
      else {
        this.deckReshufflesTextObj.style.setFontSize(70);
      }
      this.deckReshufflesTextObj.setText(val);
      this.game.dnrConfig.set("gameDeckReshuffles", val);
      this.game.scene.keys.PlayGame.setGameDeckReshuffles(val);
    }));
  }

  ////// shared functions
  createIcon = function (scene, item, textKey, fileKey, loadCallback, scaleIconsTo) {
    let bgColor = 0xffffff;
    var label = scene.rexUI.add.label({
      background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 14, bgColor),
      orientation: 'y',
      icon: (fileKey) ? scene.add.sprite(0, 0, item[fileKey]).setScale(scaleIconsTo) : '',
      text: scene.add.text(0, 0, item[textKey], { font: "32px Arial", color: "#000000" }),
      name: item[textKey],
      space: { icon: 3 },
    });
    label.enableLayoutWarn(false);
    //label.setDepth(10000004);
    loadCallback.call(this, scene, label);
    return label;
  };

  createTable({ scene, data, key, rows = 1, columns = 10, textKey, fileKey, loadCallback, scrollMode = 0, clickCallback, width = 1, height = 330, showTitle = false, showSlider = true, x = 50, y = 280, scaleIconsTo = 1, singleValue = null }) {
    let self = this;
    const keyAsCamel = _.camelCase(key);
    const keyAsPascal = keyAsCamel.charAt(0).toUpperCase() + keyAsCamel.slice(1);
    let scrollablePanel = scene.rexUI.add.scrollablePanel({
      x: x,
      y: y,
      name: key,
      width: width,
      height: height,
      scrollMode: scrollMode,
      background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, 0xffffff),
      panel: {
        child: scene.rexUI.add.fixWidthSizer({
          space: {
            left: 10,
            right: 10,
            top: 15,
            bottom: 3,
            item: 8,
            line: 8,
          }
        }).enableLayoutWarn(false),
        width: width - 100, // was width / 2 which worked as well
        mask: {
          mask: true,
          padding: { right: -15, },
          updateMode: 'everyTick'
        }
      },
      scroller: {},
      slider: showSlider ? {
        track: scene.rexUI.add.roundRectangle(0, 0, 20, 10, 10, PlayGame.COLOR_LIGHT),
        thumb: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, PlayGame.COLOR_GOLD),
      } : {},
      space: {
        left: 10,
        right: 10,
        top: 8,
        bottom: 8,
        panel: 8,
      }
    }).enableLayoutWarn(false).setOrigin(0).layout();
    this.panels[key] = scrollablePanel;
    var items = data;
    //var columns = Math.ceil(items.length / rows);
    // TODO look at moving this to another function so we can re-use it more easily to update a specific panel such as when changing face card we can update face back options
    var table = scene.rexUI.add.gridSizer({
      column: columns,
      row: rows,
      rowProportions: 1,
      space: { column: 15, row: 15 },
      name: key  // Search this name to get table back
    }).enableLayoutWarn(false);//.setDepth(10000003).enableLayoutWarn(false);
    // table.setChildrenInteractive().on('child.click', clickCallback, this);
    var item, r, c;
    if (singleValue != null) {
      scrollablePanel.setChildrenInteractive().on('child.click', clickCallback, this);
      let bgColor = 0xffffff;
      if (singleValue < 1) {
        singleValue = PlayGame.BASE_VALUES[singleValue];
      }
      let fontSize = 110;
      if (!Number(singleValue)) { // if it's not a number
        fontSize = 70;
      }
      var label = scene.rexUI.add.label({
        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 14, bgColor),
        orientation: 'y',
        icon: '',
        text: scene.add.text(0, 0, singleValue, { font: `${fontSize}px Arial`, color: "#000000" }),
        name: `number${keyAsPascal}`,
        space: { 
          left: 80,
          right: 1,
          top: 70,
          bottom: 50,},
      });
      table.add(label, 0, 0, 'top', 0, true);
      this[`${keyAsCamel}TextObj`] = label.children[1];
    }
    else {
      table.setChildrenInteractive().on('child.click', clickCallback, this);
      for (var i = 0, cnt = items.length; i < cnt; i++) {
        item = items[i];
        r = i % rows;
        c = (i - r) / rows;
        table.add(
          this.createIcon(scene, item, textKey, fileKey, loadCallback, scaleIconsTo),
          c,
          r,
          'top',
          0,
          true
        );
      }
    }
    scrollablePanel.getElement('panel').addBackground(
      scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, undefined)
    );
    if (showTitle) {
      var capKey = key.charAt(0).toUpperCase() + key.slice(1);
      var title = scene.rexUI.add.label({
        width: width - 100,
        orientation: 'x',
        text: scene.add.text(0, 0, capKey, { font: "32px Arial", color: "#000000" }),
      });//.setDepth(10000006);
      scrollablePanel.getElement('panel').add(
          title, // child
          0, // proportion
          'left', // align
          0, // paddingConfig
          true // expand
        );
    }
    scrollablePanel.getElement('panel').add(
      table, // child
      1, // proportion
      'left', // align
      0, // paddingConfig
      true // expand
    );
    scrollablePanel.layout();
    return scrollablePanel;
  }

  update() { }
}
