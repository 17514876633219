import Config from '../classes/Config';
import GameSelection from './GameSelection';
import PlayGame from './PlayGame';
import { NativeAudio } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';

export default class Intro extends Phaser.Scene {
  constructor() {
    super({
      key: 'Intro'
    });
  }

  preload() { // load all assets used in game
    // this.load.image('kilna', 'src/assets/kilna.jfif');
    // TODO preload all cards in deck
    var width = this.cameras.main.width;
    var height = this.cameras.main.height;
    this.loadingText = this.make.text({
      x: width / 2,
      y: height / 2,
      text: '...',
      style: {
          font: '12em Arial',
          fill: '#ffffff'
      }
    });
    this.loadingText.setOrigin(0.5);
    this.load.maxParallelDownloads = 5;
    this.load.image('decknroll_logo', 'assets/DECKnROLL.png');
    this.load.image('avalanche_logo', 'assets/AVALANCHE.png');
    this.load.image('deck64_game', 'assets/deck64_game.png');
    this.load.image('deck64_logo', 'assets/deck64_logo.png');
    // this.load.image('blankavatar', 'assets/blankavatar.png');
  }

  create() { // beginning of game where everything is populated
    let self = this;
    self.game.dnrConfig = new Config();
    this.gameSoundLoadCount = 0;
    if (Capacitor.getPlatform() != 'web') {
      NativeAudio.configure({fade: false});
    }
    PlayGame.sounds.forEach((name)=>{
      NativeAudio.isPlaying({ // we don't really care if the sound is playing...
        // but if this goes to the 'then' block then the sound is already loaded
        // but if it goes to the 'catch' block then the sound needs to be loaded
        // this is to prevent a bug on mobile when you leave the game for a while
        // to go to another app or home screen, when you go back to the game it
        // goes back to this Intro, but it hangs as though the audio loaded
        assetId: name
      }).then((v)=> {
        this.gameSoundLoadCount++;
      }).catch((e) => {
        NativeAudio.preload({
          assetId: name,
          assetPath: `${PlayGame.capAssetsPath}sounds/${name}.mp3`,
          audioChannelNum: 1,
          isUrl: false
        }).then((v) => {
          this.gameSoundLoadCount++;
          // console.log('gamesoundloadcount', this.gameSoundLoadCount)
        });
      })
      this.gameSoundLoadCount++;
    });
    // let deck64Logo = new Phaser.GameObjects.Image(this, this.cameras.main.width / 2, self.cameras.main.height / 2, 'decknroll_logo');
    // deck64Logo.setScale(1.5);
    // this.add.existing(deck64Logo);
    let deck64Logo = new Phaser.GameObjects.Image(this, this.cameras.main.width / 2, -300, 'deck64_logo');
    deck64Logo.setScale(.5);
    this.add.existing(deck64Logo);
    let decknrollLogo = new Phaser.GameObjects.Image(this, this.cameras.main.width * .65, this.game.config.height + 500, 'decknroll_logo');
    decknrollLogo.setScale(1);
    this.add.existing(decknrollLogo);
    this.loadingText.destroy();
    self.tweens.chain({
      targets: deck64Logo,
      tweens: [
        {
          y: self.cameras.main.height / 3,
          duration: 200,
        },
        {
          scale: .4,
          ease: 'Expo',
          duration: 1000,
          repeat: 0,
          onComplete: () => {
            self.tweens.chain({
              targets: decknrollLogo,
              tweens: [
                {
                  scale: .5,
                  ease: 'Expo',
                  y: self.game.config.height * .7,
                  duration: 2000,
                  repeat: 0,
                  onStart: () => {
                    self.game.playSound('deck64');
                  },
                  onComplete: () => {
                    self.animationComplete = true;
                  }
                },
              ]
            }).play();
          }
        },
      ]
    }).play();

    // this.load.image('background', 'assets/cotton_candy_mosaic_bg.png');
    Object.keys(PlayGame.themes).forEach((o)=>{
      this.load.image(PlayGame.themes[o].background.key, `assets/${PlayGame.themes[o].background.asset}`);
    });
    PlayGame.computerPlayers.forEach( (player) => {
      this.load.image(player.avatar, `assets/${player.avatar}.png`);
    });

    PlayGame.decks.forEach( (deck) => {
      this.load.image(`${deck}-icon`, `assets/deck/${deck}/icon.png`);
    });
    PlayGame.gameDifficulties.forEach( (level) => {
      this.load.image(level.file, `assets/${level.file}.png`);
    });
    PlayGame.gameAudio.forEach( (audio) => {
      this.load.image(audio.file, `assets/${audio.file}.png`);
    });
    PlayGame.gameLearningMode.forEach( (mode) => {
      this.load.image(mode.file, `assets/${mode.file}.png`);
    });
    GameSelection.games.forEach( (game) => {
      this.load.image(game.key, `assets/${game.key}.png`);
    });

    ((async () => await self.game.dnrConfig.get("gameTheme"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'forestmoon' : JSON.parse(v);
      self.game.gameTheme = v;
    }));

    ((async () => await self.game.dnrConfig.get("gameDeckFace"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? PlayGame.decks[4] : JSON.parse(v); // default to 'loyal' index 4 in PlayGame.decks
      self.game.gameDeckFace = v;
    }));

    // TODO re-enable once we want to allow changing the deck backs
    ((async () => await self.game.dnrConfig.get("gameDeckBack"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'main' : JSON.parse(v);
      self.game.gameDeckBack = v;
    }));

    // ((async () => await self.game.dnrConfig.get("gameAvatar"))().then((v)=>{
    //   // console.log(v);
    //   v = (v == null) ? 'Pete' : JSON.parse(v);
    //   self.game.gameAvatar = v;
    // }));

    ((async () => await self.game.dnrConfig.get("gameDifficulty"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'easy' : JSON.parse(v);
      self.game.gameDifficulty = v;
    }));

    ((async () => await self.game.dnrConfig.get("gameDeckReshuffles"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? '1' : JSON.parse(v);
      self.game.gameDeckReshuffles = v;
    }));

    ((async () => await self.game.dnrConfig.get("gameInitialCardsDealt"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? '7' : JSON.parse(v);
      self.game.gameInitialCardsDealt = v;
    }));

    ((async () => await self.game.dnrConfig.get("gameAudio"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'on' : JSON.parse(v);
      self.game.gameAudio = v;
      self.game.setGameAudio(v);
    }));

    ((async () => await self.game.dnrConfig.get("gameLearningMode"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'on' : JSON.parse(v);
      self.game.gameLearningMode = v;
      self.game.setGameLearningMode(v);
    }));

    // ((async () => await self.game.dnrConfig.get("gamePointsInCurrentLevel"))().then((v)=>{
    //   // console.log(v);
    //   v = (v == null) ? '0' : JSON.parse(v);
    //   self.game.gamePointsInCurrentLevel = v;
    // }));

    this.game.getDnrToken();

    ((async () => await self.game.getDnrToken())().then(async (v)=>{
      // console.log(v);
      await self.game.getUserInfoFromUDS()
    }));

    ((async () => await self.game.dnrConfig.get("gameCurrentLevel"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? '1' : JSON.parse(v);
      self.game.gameCurrentLevel = v;
    }));

    ((async () => await self.game.dnrConfig.get("gameSpeed"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'slow' : JSON.parse(v);
      self.game.gameSpeed = v;
    }));

    ((async () => await self.game.dnrConfig.get("gamePlayerName"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? 'Guest' : JSON.parse(v);
      self.game.gamePlayerName = v;
    }));

    ((async () => await self.game.dnrConfig.get("gameHints1"))().then((v)=>{
      // console.log(v);
      v = (v == null) ? '0' : JSON.parse(v);
      self.game.gameHints1 = v;
    }));

    this.load.once(Phaser.Loader.Events.COMPLETE, () => {
      // self.loadComplete = true;
      self.finishLoading = this.time.addEvent({
        delay: 1500,
        callback: () => {
          if (self.animationComplete
              && self.game.gameTheme
              && self.game.gameDeckFace
              && self.game.gameDeckBack
              // && self.game.gameAvatar
              && self.game.gameDifficulty
              && self.game.gameAudio
              && self.game.gameLearningMode
              && self.game.gameSpeed
              && self.game.gameInitialCardsDealt
              && self.game.gameDeckReshuffles
              && self.game.gamePlayerName
              && self.gameSoundLoadCount >= PlayGame.sounds.length) {
            const deck = self.game.gameDeckFace;
            self.load.image(`${deck}-back-main`, `assets/deck/${deck}/back-main.png`);
            self.load.image(`${deck}-back-alt`, `assets/deck/${deck}/back-alt.png`);
            // self.load.image(`${deck}-icon`, `assets/deck/${deck}/icon.png`);
            PlayGame.cards.colors.forEach( (color) => {
              PlayGame.cards.numbers.forEach( (rank) => {
                PlayGame.cards.suits.forEach( (suit) => {
                  self.load.image(`${deck}-`+color+rank+suit, `assets/deck/${deck}/${color}${rank}${suit}.png`);
                })
              })
            })
            PlayGame.icons.forEach( (icon) => {
              self.load.image(`icon-${icon}`, `assets/${icon}.png`)
            })
            
            self.load.once(Phaser.Loader.Events.COMPLETE, () => {
              self.tweens.chain({
                targets: deck64Logo,
                tweens: [
                  {
                    x: self.cameras.main.width + 1500,
                    ease: 'Expo',
                    duration: 200,
                    onComplete: () => {
                      this.time.removeEvent(this.finishLoading);
                      this.scene.start('GameSelection').stop('Intro').remove('Intro');
                    }
                  }
                ]
              }).play();
            });
            self.load.start();
          }
        },
        loop: true
      });

      this.load.start();
    });
    this.load.start();
  }

  update() {

  }
}
