import GameButton from '../classes/GameButton';
import CardSet from '../classes/CardSet';
import Card from '../classes/Card';
import Seat from '../classes/Seat';
import PopInText from '../classes/PopInText';
import ProgressBar from '../classes/ProgressBar';
import {NativeAudio} from '@capacitor-community/native-audio';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';

export default class PlayGame extends Phaser.Scene {
  constructor() {
    super({
      key: 'PlayGame'
    });
  }

  DEBUG = false; // test

  static playerTurnOrder = [0, 2, 1, 3];

  static availActions = {
    DRAW: 'draw',
    DISCARD: 'discard',
    ENDTURN: 'end_turn',
    PULL: 'pull'
  }

  static tieBreakerReasoning = ["having the\nleast number of cards in hand!",
    "having more\nmatching attributes in common\nwith the last discard card!",
    "having the\nhighest ranked card!"];

  // !!!IMPORTANT!!! the order of the colors, numbers, and suits below are in ranked order lowest to highest and are used in that order for comparison (e.g. index 0 is less than index 3 for a reason)
  // these get loaded in Intro.js
  static cards = {colors: ['rd', 'gn', 'bu', 'bk'], numbers: ['1', '2', '3', '4'], suits: ['h', 'd', 'c', 's']}; // decks: [{id: 'tiltface', name: 'deck1'}]
  static cardAttributeReference = {
    rd: 'Red',
    gn: 'Green',
    bu: 'Blue',
    bk: 'Black',
    h: 'Hearts',
    d: 'Diamonds',
    c: 'Clubs',
    s: 'Spades',
  }

  static levelUpFactor = 300; // this number of points times level number to determine how many points needed to level up
  // these get loaded in Intro.js
  static sounds = [
    "deck64",
    "discard1",
    "discard2",
    "discard3",
    "discard4",
    "discard5",
    "discard6",
    "discard7",
    "discard8",
    "discard9",
    "discard10",
    "discardfail",
    "discardmax",
    "lose",
    "noplay",
    "select",
    "whoop2",
    "win",
    "yourturn",
    "d64-theme"
  ];
  // these get loaded in Intro.js
  static icons = ["1attribMatchBonus"];
  // these get loaded in Intro.js
  static computerPlayers = [
    {name: 'Pete', avatar: 'mr_aces'},
    {name: 'Raul', avatar: 'golfer'},
    {name: 'Jack', avatar: 'mr_chair'},
    {name: 'Raven', avatar: 'ms_raven'},
    {name: 'Ann', avatar: 'ms_ann'},
    {name: 'Cruz', avatar: 'mr_moto'},
    {name: 'Jade', avatar: 'ms_poker'},
    {name: 'Viviana', avatar: 'ms_futbol'},
    {name: 'Kilna', avatar: 'mr_kilna'},
    {name: 'Robert', avatar: 'mr_robert'},
    {name: 'Ryan', avatar: 'mr_ryan'},
    {name: 'Jazz', avatar: 'mr_jazz'},
    {name: 'Mel', avatar: 'ms_mel'},
    {name: 'Tommy', avatar: 'mr_tommy'},
    {name: 'Carley', avatar: 'ms_carley'},
    {name: 'Charles', avatar: 'mr_charles'},
    {name: 'Mike', avatar: 'mr_mike'},
    {name: 'Tiana', avatar: 'ms_tiana'},
  ];

  static initialCardsDealtOptions = [
    {
      title: '5'
    },
    {
      title: '6'
    },
    {
      title: '7'
    },
    {
      title: '8'
    },
    {
      title: '9'
    },
    {
      title: '10'
    },
    {
      title: '11'
    },
    {
      title: '12'
    },
  ]

  static BASE_VALUES = {
    infinite: -1,
    none: 0,
    '-1': 'infinite',
    '0': 'none'

  }

  static deckReshufflesOptions = [
    {
      title: 'infinite'
    },
    {
      title: 'none'
    },
    {
      title: '1'
    },
    {
      title: '2'
    },
    {
      title: '3'
    },
  ]

  static gameSpeeds = {
    // default: {}, // will use whatever gameDifficulties is set to
    slow: {
      name: 'slow',
      timeBetweenCardDiscard: 1200,
      additionalTimeToWaitBeforeFinishTurn: 2400,
      discardQueueMoveDuration: 800, 
      initialWait: 750
    },
    medium: {
      name: 'medium',
      timeBetweenCardDiscard: 1200,
      additionalTimeToWaitBeforeFinishTurn: 2400,
      discardQueueMoveDuration: 800, 
      initialWait: 750
    },
    fast: {
      name: 'fast',
      timeBetweenCardDiscard: 700,
      additionalTimeToWaitBeforeFinishTurn: 1400,
      discardQueueMoveDuration: 400, 
      initialWait: 450
    },
    insane: {
      name: 'insane',
      timeBetweenCardDiscard: 400,
      additionalTimeToWaitBeforeFinishTurn: 900,
      discardQueueMoveDuration: 200, 
      initialWait: 150
    },
  };
  // these get loaded in Intro.js
  static gameDifficulties = [
    { 
     level: 'easy',
     file: 'level_1',
     pointsPerCard: 10,
     pointsPerWin: 500,
     gameSpeed: PlayGame.gameSpeeds.slow,
     gameSpeedRef: 'slow' // for backwards compat for removing default speed
    },
    {
     level: 'medium',
     file: 'level_2',
     pointsPerCard: 15,
     pointsPerWin: 1600,
     gameSpeed: PlayGame.gameSpeeds.medium,
     gameSpeedRef: 'medium' // for backwards compat for removing default speed
    },
    {
     level: 'hard',
     file: 'level_3',
     pointsPerCard: 20,
     pointsPerWin: 3200,
     gameSpeed: PlayGame.gameSpeeds.fast,
     gameSpeedRef: 'fast' // for backwards compat for removing default speed
    }
  ];
  // these get loaded in Intro.js
  static gameAudio = [
    {mode: 'on', file: 'audio_on'},
    {mode: 'off', file: 'audio_off'}, // referenced by game.playSound(assetId)
    {mode: 'music off', file: 'audio_music_off'},
  ]
  // these get loaded in Intro.js
  static gameLearningMode = [
    {mode: 'on', file: 'learning_on'},
    {mode: 'off', file: 'learning_off'},
  ]

  static COLOR_PRIMARY = 0x540016;
  static COLOR_LIGHT = 0xcccccc;
  static COLOR_DARK = 0xbbbbbb;
  static COLOR_GOLD = 0xffca3a;
  static COLOR_BUTTON_CLICK = 0xf89d98;

  static TIME_BETWEEN_HINTS = 6000;
  
  // these get loaded in Intro.js
  static decks = ['noo', 'askew', 'kawaii', 'geist', 'loyal', 'sketch'];

  static arrayOfDeckFaces() {
    let arr = [];
    PlayGame.decks.forEach((o)=>{
      arr.push({ name: o, key: `${o}-icon` });
    });
    return arr;
  }

  static arrayOfDeckBacks(deckName) {
    let arr = [
      { name: 'main', key: `${deckName}-back-main` },
      { name: 'alt', key: `${deckName}-back-alt` },
    ];
    return arr;
  }
  // these get loaded in Intro.js
  static themes = {
    forestmoon: {
      background: { asset: 'forestmoon_bg.png', key: 'forestmoon_bg' },
      button_bg_color: PlayGame.COLOR_PRIMARY,
      button_text_color: PlayGame.COLOR_GOLD,
    },
    riversun: {
      background: { asset: 'riversun_bg.png', key: 'riversun_bg' },
      button_bg_color: PlayGame.COLOR_PRIMARY,
      button_text_color: PlayGame.COLOR_GOLD,
    },
    cottoncandy: {
      background: { asset: 'cotton_candy_mosaic_bg.png', key: 'cotton_candy_mosaic_bg' },
      button_bg_color: PlayGame.COLOR_PRIMARY,
      button_text_color: PlayGame.COLOR_GOLD,
    },
    cityart: {
      background: { asset: 'city_art_bg.png', key: 'city_art_bg' },
      button_bg_color: PlayGame.COLOR_PRIMARY,
      button_text_color: PlayGame.COLOR_GOLD,
    },
    elclasico: {
      background: { asset: 'el_clasico_bg.png', key: 'el_clasico_bg' },
      button_bg_color: PlayGame.COLOR_PRIMARY,
      button_text_color: PlayGame.COLOR_GOLD,
    }
  };

  static arrayOfThemes() {
    let arr = [];
    Object.keys(PlayGame.themes).forEach((o)=>{
      arr.push({ name: o, key: PlayGame.themes[o].background.key });
    });
    return arr;
  }

  static capAssetsPath = Capacitor.getPlatform() == 'web' ? 'assets/' : 'public/assets/';
 
  preload() { // load all assets used in game
    this.load.atlas('flares', 'assets/flares.png', 'assets/flares.json');
  }

  create() { // beginning of game where everything is populated
    let self = this;
    // this.events.once('shutdown', () => {
      
    // });
    this.seats = {};
    this.wsinfo = {
      host: 'api.avalanche.dev.decknroll.com',
      // host: 'api.local.dev.decknroll.com:8010',
      temp: {}
    };
    this.localPlayerTurn = false;
    this.playerMustDraw = true;
    this.playerMustEnd = false;
    this.loadingText =  null;
    window.gg = self;
    window.gs = PlayGame;
    // window.na = NativeAudio;
    // window.hh = PlayGame;
    // ensure the select ding is quieter than default volume
    // NativeAudio.setVolume({
    //   assetId: 'select',
    //   volume: 0.15,
    // });
    // this.input.topOnly = false;
    // this.gameAudio = PlayGame.gameAudio[0]; // audio on
    // this.gameDifficulty = PlayGame.gameDifficulties[0];
    this.availablePlayers = Array.from({length: PlayGame.computerPlayers.length}, (v, i) => i);
    // console.log('avail players', this.availablePlayers)
    // TODO move bg stuff into separate function and also make a set bg function for when changing from settings menu
    this.bgContainer = new Phaser.GameObjects.Container(this,0,0);
    this.bgContainer.setDepth(-1000001);
    this.add.existing(this.bgContainer);
    // console.log(this.game.gameTheme);
    // ((async () => await self.game.getUserInfoFromUDS())().then((v)=>{
    //   // console.log(v);
    //   // v = (v == null) ? '1' : JSON.parse(v);
    //   // self.game.gameCurrentLevel = v;
    // }));
    this.game.setGameTheme(this.game.gameTheme, this);
    this.game.setGameDeckFace(this, this.game.gameDeckFace);
    this.game.setGameDeckBack(this, this.game.gameDeckBack);
    // this.setGameDifficulty(this.game.gameDifficulty);
    this.game.setGameAudio(this.game.gameAudio);
    this.game.setGameLearningMode(this.game.gameLearningMode);
    this._setHumanPlayer(this.game.gameAvatar);
    if (this.game.gameSpeed == 'default') { // for backwards compat since removing default speed
      this.game.gameSpeed = this.gameDifficulty.gameSpeedRef;
      this.game.dnrConfig.set("gameSpeed", this.game.gameSpeed);
    }
    this.game.setGameSpeed(this.game.gameSpeed);
    this.setGameInitialCardsDealt(this.game.gameInitialCardsDealt); // default to 7 for now
    this.setGameDeckReshuffles(this.game.gameDeckReshuffles); // default to 1 for now
    this.gameDeckReplenishCount = 0; // running counter of replenishes (not a setting or option)
    this.gameFinalTurns = null;

    this.decks = [{id: this.game.gameDeckFace, name: 'deck1'}]; // multiple decks can be added here (or just one for now)

    // TODO do a container per bonus instead of one container for all bonuses?
    // this.bonuses = {};
    // this.bonuses.oneAttributeMatch = { playerId: -1, count: 0 , icon: 'icon-1attribMatchBonus'};
    // this.bonuses.container = new Phaser.GameObjects.Container(this, 0, 0);
    // this.bonuses.container.setVisible(false);
    // this.bonuses.container.add(new Phaser.GameObjects.Image(this, 0, 0, this.bonuses.oneAttributeMatch.icon));
    // this.add.existing(this.bonuses.container);
    // this.bonuses.container.setInteractive(new Phaser.Geom.Rectangle(-75,-75,150,150), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{
    //   let playerContextual = 'This player can match\non one attribute at the\nstart of their turn!';
    //   if (this.bonuses.oneAttributeMatch.playerId == 0) {
    //     playerContextual   = 'You can match on one\nattribute at the start of\nyour turn!';
    //   }
    //   let pit = new PopInText({
    //     scene: this,
    //     x: this.bonuses.container.x,
    //     y: this.bonuses.container.y,
    //     // A player can match on one attribute at the start of their turn
    //     text: playerContextual +
    //           '\n\nThis moves to the last\n' +
    //           'player who discarded.',
    //     box: {w: 600, h: 400, radius: 30},
    //     startScale: .65,
    //     endScale: .75.15,
    //     scaleBy: 1.15,
    //     duration: 200,
    //     onComplete: ()=>{
    //       pit.closeTextUpdater({ runForSeconds: 3 });
    //     },
    //     font: "42px Arial Black",
    //   });
    // });

    this.turnTimeLimitProgressBar = new ProgressBar({scene: this,
      x: (this.game.config.width / 2) -400,
      y: this.game.config.height - 20,
      width: 800,
      height: 15,
      addToScene: true
    });
    this.turnTimeLimitProgressBar.setVisible(false);
    // this.turnTimeLimitProgressBar.setProgress({perc: 100, duration: 100})
    // this.add(this.turnTimeLimitProgressBar);

    this.playerInfo = this.game.setupPlayerInfo(this);
    this.game.gameCurrentLevelProgress = 100 * (this.game.gamePointsInCurrentLevel / (this.game.gameCurrentLevel * PlayGame.levelUpFactor));
    this.playerInfo.levelProgressBar.setProgress({perc: this.game.gameCurrentLevelProgress, instant: true});
    // NOTE this currently lets you make the progress bar longer than the box it fits in.
    //  However, this commit https://github.com/decknroll/uds-api/commit/79b79da40b147c1bbcd242baa3ec1b4c7c12d5b6
    //  should ensure we are always at the correct level for the points a user has.

    // this.playerInfo.levelProgressBar.setInteractive(new Phaser.Geom.Rectangle(0,-205,150,250), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{
    //   const pointsRequiredForNextLevel = (this.game.gameCurrentLevel * PlayGame.levelUpFactor);
    //   let pit = new PopInText({
    //     scene: this,
    //     x: this.game.config.width - 350,
    //     y: 175,
    //     // TODO use something like BBCode to make styling easier (https://rexrainbow.github.io/phaser3-rex-notes/docs/site/bbcodetext/)
    //     text: `Your score: ${this.scoreText.text}\n` +
    //           `Your level: ${this.levelText.text}\n` +
    //           `Level progress:\n` +
    //           `${Math.floor(this.game.gameCurrentLevelProgress)}%\n` +
    //           `${this.game.getCompactNumber(this.game.gamePointsInCurrentLevel)} / ${this.game.getCompactNumber(pointsRequiredForNextLevel)} points`,
    //     box: {w: 600, h: 300, radius: 30},
    //     startScale: .65,
    //     endScale: .75.15,
    //     scaleBy: 1.15,
    //     duration: 200,
    //     onComplete: ()=>{
    //       // setTimeout(()=>{
    //       //   pit.destroy();
    //       // }, 5200)
    //       pit.closeTextUpdater({ runForSeconds: 5 });
    //     },
    //     font: "42px Arial Black",
    //   });
      
    // });

    this.emitter = this.add.particles(this.game.config.width - 100, 200, 'flares', {
      frame: [ 'blue', 'white', 'red', 'yellow', 'green' ],
      lifespan: 2000,
      speed: { min: 150, max: 850 },
      scale: { start: .6, end: 0 },
      gravityY: 250,
      gravityX: 250,
      frequency: 100,
      quantity: 20,
      blendMode: 'ADD',
      emitting: false
    });

    // Note this is rarely ever called atm as we force devices
    //   to landscape by default
    // const orientationReminder = ()=> {
    //   setTimeout(()=> {
    //   if (this.scale.orientation === Phaser.Scale.PORTRAIT) {
    //     this.orientationMessageButton = new GameButton({scene: this,
    //       x: 15,
    //       y: 15,
    //       w: this.game.config.width - 30,
    //       h: this.game.config.height - 30,
    //       depth: 1400000,
    //       text: 'To best experience this game\n' +
    //             'please rotate your device to landscape.\n' +
    //             '\nTouch/click this to dismiss.',
    //       font: "95px Arial",
    //       radius: 10,
    //       clickFunction: ()=>{
    //           this.orientationMessageButton.destroy();
    //       },
    //       clickFunctionData: {},
    //     });
    //   } else {
    //     this.orientationMessageButton && this.orientationMessageButton.destroy();
    //   }
    // }, 500);
    // }
    // this.scale.on('orientationchange', orientationReminder);
    // orientationReminder();

    //// setup deck
    this.deck = new CardSet({
      scene: this,
      name: 'DrawDeck',
      x: this.game.config.width / 2 - 164,
      y: this.game.config.height / 2,
      z: -20,
      stepStack: 10,
      angle: 10,
      angleCard: 0.02,
      angleCardRandom: 2,
      depth: -15000,
      xSpread: -0.08,
      ySpread: -0.25,
    });
    this.loadCardsInDeck();
    
    //// setup discard pile
    this.discardPile = new CardSet({
      scene: this,
      name: 'DiscardPile',
      x: this.game.config.width / 2,
      y: this.game.config.height / 2,
      z: 0,
      depth: -11000,
      xSpread: -0.15,
      ySpread: -0.4,
      angle: 0,
      angleCardRandom: 10,
      positionTweenDuration: 500,
    });

    this.failedDiscardPile = new CardSet({
      scene: this,
      name: 'FailedDiscardPile',
      x: this.game.config.width / 2,
      y: this.game.config.height / 2,
      z: 7,
      depth: 1500000000,
      angle: 0,
      angleCardRandom: 10,
      positionTweenDuration: 0,
    });

    // this.game.playSound('d64-theme', true);

    const introBoxWidth = 980;
    this.introMessage = new PopInText({
      scene: this,
      x: this.game.positionConfig.introMessage.x,
      y: this.game.positionConfig.introMessage.y,
      text: '\n\n\nTo win, get rid of all your cards.\n' +
            'Each card is a unique combination of\n' +
            'a color, a suit and a rank. A valid\n' +
            'discard play matches on at least 2 of\n' +
            'those attributes. After playing your\n' +
            'card(s) press end to finish your turn.',
      box: {w: introBoxWidth, h: 375, radius: 15},
      startScale: 1,
      endScale: .85,
      scaleBy: 1.15,
      duration: 100,
      onComplete: ()=>{
        //
      },
      font: "32px Arial Black",
    });
    const avalancheLogo = new Phaser.GameObjects.Image(this, 0, -125, 'avalanche_logo');
    avalancheLogo.setOrigin(0.5);
    avalancheLogo.setScale(.75);
    avalancheLogo.setDepth(1300000);
    this.time.delayedCall(100, () => {
      this.introMessage.add(avalancheLogo);
    });
    
    this.debugMenuBtn = new GameButton({scene: this,
      x: 145,
      y: 45,
      w: 85,
      h: 85,
      text: `*`,
      font: "75px Arial",
      radius: 45,
      visible: false,
      clickFunction: ()=>{
          this.debugMenu.setVisible(!this.debugMenu.visible);
      },
      clickFunctionData: {},
    });
   
    this.debugMenu = new GameButton({scene: this,
      x: 45,
      y: 100,
      w: this.game.config.width - 95,
      h: this.game.config.height - 150,
      depth: 3000005,
      // add anything to the text output below to see it in the debug menu
      text: `window.location.href = ${window.location.href}`,
      // text: `window.innerHeight = ${window.innerHeight}\n` + 
      //       `window.innerWidth = ${window.innerWidth}\n` +
      //       `gameTheme = ${self.game.gameTheme}\n` +
      //       `gameDeckFace = ${self.game.gameDeckFace}\n` +
      //       `gameAudio = ${self.game.gameAudio}\n` +
      //       `gameLearningMode = ${self.game.gameLearningMode}\n`,
      font: "45px Arial",
      visible: false,
      radius: 8,
      clickFunction: ()=>{
          
      },
      clickFunctionData: {},
    });

    //// setup discard queue
    this.discardQueue = new CardSet({
      scene: this,
      name: 'DiscardQueue',
      x: this.game.config.width / 2,
      y: this.game.config.height / 2,
      z: 7,
      depth: 1000,
      angleCardRandom: 10,
      xSpread: 48,
      ySpread: -0.25,
      xSpreadMin: 35,
      ySelectOffset: 1,
      positionTweenDuration: 500,
      maxWidth: this.game.config.width * 0.75,
      addCardHandler: ({fromCardSet, handlerDelay, card})=>{
        try {
          let cardA = card;
          let idx = this.discardQueue.getIdxByCardId(card.card);
          let cardB = this.discardQueue.list[idx-1];
          // console.log(cardA, cardB, idx);
          // only let first card get the oneattributebonus
          // const hasOneAttributeBonus = this.discardQueue.list.length == 2 && this.bonuses.oneAttributeMatch.count >= Object.values(this.seats).length && this.bonuses.oneAttributeMatch.playerId == fromCardSet.seat.id;
          let matchingAttributeInfo = {
            numRequiredMatchingAttributesOnFirstCard: 2,
            text: `You need at least 2\nmatching attributes.`,
            // numRequiredMatchingAttributesOnFirstCard: hasOneAttributeBonus ? 1 : 2,
            // text: hasOneAttributeBonus ? `You need at least 1\nmatching attribute.` : `You need at least 2\nmatching attributes.`,
          }
          if (this.numAttributesMatching(cardA, cardB) < matchingAttributeInfo.numRequiredMatchingAttributesOnFirstCard) {
            // console.log('cardB is not a valid play:', cardB.card);
            // THIS now comes from the server as a failed discard message to the websocket
            //   but saving this for reference for when we want to help the player
            //   with a hint as to why their discard was invalid
            if (!this.isOnline()) {
              this.discardQueue.moveCardToCardSet({ toCardSet: fromCardSet, 
                idx: idx, 
                spin: 3, 
                delay: 601, 
                faceUp: this.localPlayerTurn, 
                style: 'default',
                preCall: ()=>{
                  self.game.playSound('discardfail');
                  // console.log('discard fail');
                  if (this.game.gameLearningMode.mode == 'on') {
                    // console.log('learning mode');
                    let colorMatch = cardB != null && cardA.cardAttributes.color == cardB.cardAttributes.color ? `\nColor: ${PlayGame.cardAttributeReference[cardA.cardAttributes.color]}` : '';
                    let rankMatch = cardB != null && cardA.cardAttributes.rank == cardB.cardAttributes.rank ? `\nRank: ${cardA.cardAttributes.rank}` : '';
                    let suitMatch = cardB != null && cardA.cardAttributes.suit == cardB.cardAttributes.suit ? `\nSuit: ${PlayGame.cardAttributeReference[cardA.cardAttributes.suit]}` : '';
                    let text = (colorMatch.length > 0 || rankMatch.length > 0 || suitMatch.length > 0) ?
                      `You only matched on ${colorMatch}${rankMatch}${suitMatch}` :
                      `You didn't match on color,\n rank or suit.`;
                    text += `\n${matchingAttributeInfo.text}`;
                    const pit = new PopInText({
                      scene: this,
                      x: this.discardQueue.x,
                      y: this.discardQueue.y + cardA.height / 3,
                      // text: `get that\nshit out!`,
                      text: text,
                      box: {w: 1150, h: 500, radius: 30},
                      startScale: .65,
                      endScale: .75,
                      scaleBy: 1.15,
                      duration: 200,
                      onComplete: ()=>{
                        pit.closeTextUpdater({ runForSeconds: 3 });
                      },
                      font: "66px Arial Black",
                    });
                  }
                }
              } );
            }
          }
          else {
            // console.log(this.gameLearningMode, this);
            if (this.game.gameLearningMode.mode == 'on') {
              let colorMatch = cardB != null && cardA.cardAttributes.color == cardB.cardAttributes.color ? `\nColor: ${PlayGame.cardAttributeReference[cardA.cardAttributes.color]}` : '';
              let rankMatch = cardB != null && cardA.cardAttributes.rank == cardB.cardAttributes.rank ? `\nRank: ${cardA.cardAttributes.rank}` : '';
              let suitMatch = cardB != null && cardA.cardAttributes.suit == cardB.cardAttributes.suit ? `\nSuit: ${PlayGame.cardAttributeReference[cardA.cardAttributes.suit]}` : '';
              let text = cardB == null ? `` : `Matched on ${colorMatch}${rankMatch}${suitMatch}`;
              // console.log('addHandlerDelay', handlerDelay)
              const pit = new PopInText({
                scene: this,
                x: this.game.config.width * fromCardSet.seat.seatPosition.popInText.x,
                y: this.game.config.height * fromCardSet.seat.seatPosition.popInText.y,
                // x: fromCardSet.x, // this.discardQueue.x,
                // y: fromCardSet.y, // this.discardQueue.y + cardA.height / 4,
                text: `${text}`,
                delay: handlerDelay,
                box: {w: 550, h: 230, radius: 30},
                startScale: .75,
                endScale: .65,
                scaleBy: .9,
                duration: 150,
                onComplete: ()=>{
                  pit.closeTextUpdater({ runForSeconds: 2 });
                },
                font: "76px Arial Black",
              })
            }
          }
        }
        catch (e) {
          // just let it fail
        }
      }
    });

    ////// BUTTONS
    const btnSize = 80;
    const btnFont = "45px Arial";
    const btnVisibleDefault = false;

    var cleanupScene = () => {
      if (this.humanPlayerHint) {
        this.time.removeEvent(this.humanPlayerHint);
      }
      if (this.wsinfo.temp.socket && this.wsinfo.temp.socket.readyState < 2) {
        this.wsinfo.temp.socket.close();
      }
      this.scene.start('GameSelection').stop('PlayGame').remove('PlayGame');
    }

    // ✖️ 🚪
    this.leaveCurrentGameBtn = new GameButton({scene: this,
      x: 25,
      y: 25,
      w: btnSize / 2 + 10,
      h: btnSize / 2 + 10,
      text: `🚪`,
      font: "65px Arial",
      radius: 45,
      borderWidth: 0,
      bgAlpha: .01,
      visible: false,
      clickFunction: ()=>{
        if (!this.leaveCurrentGameBtn.disabled) {
          if (this.game.gameInProgress) {
            ((async () => await Dialog.confirm({
                title: 'Leaving current game',
                message: 'Leave this game?', // this.game.gameInProgress ? `Note: Your points from this game will not be saved.`,
            }))().then((v)=>{
              // console.log(v);
              if (v.value) {
                cleanupScene();
              }
            }));
          }
          else {
            cleanupScene();
          }
        }
      },
      clickFunctionData: {},
    });

    this.explainEmptyDrawPileBtn = new GameButton({scene: this,
      x: this.game.config.width / 2 - 400,
      y: this.game.config.height / 2,
      w: btnSize / 2 + 10,
      h: btnSize / 2 + 10,
      text: `?`, // 🇽 doesn't look good on android (makes it blue)
      font: "75px Arial",
      radius: 45,
      borderWidth: 0,
      visible: false,
      clickFunction: ()=>{
        const pit = new PopInText({
          scene: this,
          x: this.game.config.width / 2,
          y: this.game.config.height / 2,
          text: 'The draw pile is now empty and will not\n' +
                'be refilled. Each player now has one\n' +
                'more turn. If no players are able to\n' +
                'empty their hand, a win is rewarded to\n' +
                'the player with least cards in their hand.\n' +
                'Any ties are won by the player with the\n' +
                'most attributes in common with the last\n' +
                'discarded card.',
          box: {w: 1800, h: 800, radius: 30},
          startScale: .65,
          endScale: .75,
          scaleBy: .9,
          duration: 200,
          onComplete: ()=>{
            // do nothing (overwrite default destroy behavior)
          },
          font: "66px Arial Black",
        })
      },
      clickFunctionData: {},  
    });

    if (self.game.gameHints1 < 1 && self.game.gameCurrentLevel > 3) {
      // 💡
      this.suggestGameSpeeds = new GameButton({scene: this,
        x: 365,
        y: 45,
        w: btnSize / 2 + 20,
        h: btnSize / 2 + 50,
        text: `💡`,
        font: "75px Arial",
        radius: 15,
        // borderWidth: 0,
        visible: true,
        clickFunction: ()=>{
          const pit = new PopInText({
            scene: this,
            x: this.game.config.width / 2,
            y: this.game.config.height / 2 - 100,
            text: 'You look like you\'re getting the hang\n' +
                  'of it. You can disable the extra tips \n' +
                  '(learning mode) from the settings by touching\n' + 
                  'your avatar in the top right.\n\n' +
                  'Good luck and enjoy!',
            box: {w: 1120, h: 475, radius: 15},
            startScale: .65,
            endScale: .75,
            scaleBy: 1,
            duration: 200,
            onComplete: ()=>{
              this.game.dnrConfig.set("gameHints1", ++this.game.gameHints1);
              this.suggestGameSpeeds.destroy();
              // do nothing (overwrite default destroy behavior)
            },
            font: "58px Arial Black",
          })
        },
        clickFunctionData: {},
      });
    }

    this.playOnlineButton = new GameButton({scene: self,
      x: self.game.positionConfig.playOnlineButton.x,
      y: self.game.positionConfig.playOnlineButton.y,
      w: 220,
      h: 130,
      depth: 2000001,
      text: `Play`,
      font: "52px Arial",
      radius: 32,
      clickFunction: async ()=>{
          // this.introMessage.setVisible(false);
          this.playOnlineButton.setVisible(false);
          // this.backToPlayGameStartButton.setVisible(true);
          this.backToGameSelectionButton.setVisible(false);
          this.introMessage.setVisible(false);
          await this.startOnlineGame();
      },
      clickFunctionData: {},
    });

    // this.backToPlayGameStartButton = new GameButton({scene: this,
    //   x: 600,
    //   y: this.game.config.height - 190,
    //   w: 300,
    //   h: 120,
    //   text: `Back`,
    //   font: "55px Arial",
    //   radius: 45,
    //   visible: false,
    //   clickFunction: ()=>{
    //     this.scene.start('PlayGame');
    //     if (this.isOnline()) {
    //       this.wsinfo.temp.socket.close();
    //     }
    //     this.playOnlineButton.setVisible(true);
    //     // this.startButton.setVisible(false);
    //     // this.gameOptionsBtn.setVisible(false);
    //     this.seats = {};
    //     this.backToPlayGameStartButton.setVisible(false);
    //     this.backToGameSelectionButton.setVisible(true);
    //   },
    //   clickFunctionData: {},
    // });

    this.backToGameSelectionButton = new GameButton({scene: this,
      x: this.game.positionConfig.backToGameSelectionButton.x,
      y: this.game.positionConfig.backToGameSelectionButton.y,
      w: 175,
      h: 80,
      text: `Back`,
      font: "35px Arial",
      radius: 32,
      clickFunction: ()=>{
        this.playOnlineButton.setVisible(false);
        this.backToGameSelectionButton.setVisible(false);
        this.scene.start('GameSelection');
      },
      clickFunctionData: {},
    });

    // TODO build this so we can choose game options that other players can see as well but only the host can change
    this.gameOptionsBtn = new GameButton({scene: this,
      x: 100,
      y: this.game.config.height - 230,
      w: 400,
      h: 180,
      text: `Options`,
      font: "75px Arial",
      radius: 45,
      visible: false,
      clickFunction: ()=>{
          this.scene.run('GameOptions');
          this.scene.setVisible(true, 'GameOptions');
      },
      clickFunctionData: {},
    });

    this.leftControlsContainer = new Phaser.GameObjects.Container(this, 30, this.getHeight() - 200);
    this.add.existing(this.leftControlsContainer);

    // this.startButton.select();
    this.leftArrowBtnClick = () => {
      this.checkPlayerRecommendedNextAction({});
      this.seats[this.game.seat_id].hand.selectCursorLeft();
      this.game.playSound('select');
    }
    this.leftArrowBtn = new GameButton({scene: this,
      x: 0,
      y: 45,
      w: btnSize,
      h: btnSize,
      text: `⇦`,
      font: btnFont,
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.leftArrowBtnClick,
      clickFunctionData: {},
      addToScene: false,
      parentContainer: this.leftControlsContainer
    });
    this.rightArrowBtnClick = () => {
      this.checkPlayerRecommendedNextAction({});
      this.seats[this.game.seat_id].hand.selectCursorRight();
      this.game.playSound('select');
    }
    this.rightArrowBtn = new GameButton({scene: this,
      x: 180,
      y: 45,
      w: btnSize,
      h: btnSize,
      text: `⇨`,
      font: btnFont,
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.rightArrowBtnClick,
      clickFunctionData: {},
      addToScene: false,
      parentContainer: this.leftControlsContainer
    });
    this.playBtnClick = () => {
      this.playCardButtonClick();
    }
    this.playBtn = new GameButton({scene: this,
      x: 90,
      y: 0,
      w: btnSize,
      h: btnSize,
      text: `Play\ncard`,
      font: "28px Arial",
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.playBtnClick,
      clickFunctionData: {},
      addToScene: false,
      parentContainer: this.leftControlsContainer
    });
    this.pullBtnClick = () => {
      if (!this.pullBtn.disabled) {
        this.pullButtonClick();
        this.configurePullBtn();
        this.configureEndTurnBtn();
      }
    }
    this.pullBtn = new GameButton({scene: this,
      x: 90,
      y: 90,
      w: btnSize,
      h: btnSize,
      text: `Pull\ncard`,
      font: "28px Arial",
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.pullBtnClick,
      clickFunctionData: {},
      addToScene: false,
      parentContainer: this.leftControlsContainer
    });
    this.drawBtnClick = () => {
      if (!this.drawBtn.disabled) {
        // console.log('dB click isOnline()', this.isOnline())
        this.drawBtn.disable(true);
        this.drawBtn.unselect();
        this.drawCard();
      }
    }
    this.drawBtn = new GameButton({scene: this,
      x: this.game.config.width - 150 - btnSize,
      y: this.game.config.height - 100,
      w: btnSize,
      h: btnSize,
      text: `Draw\ncard`,
      font: "28px Arial",
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.drawBtnClick,
      clickFunctionData: {},
    });
    this.endTurnBtnClick = () => {
      if (!this.endTurnBtn.disabled) {
        this.endTurn();
      }
    }
    this.endTurnBtn = new GameButton({scene: this,
      x: this.game.config.width - 150 - btnSize,
      y: this.game.config.height - 192,
      w: btnSize,
      h: btnSize,
      text: `End\nturn`,
      font: "28px Arial",
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.endTurnBtnClick,
      clickFunctionData: {},
    });
    this.swapLeftBtnClick = () => {
      this.checkPlayerRecommendedNextAction({});
      this.seats[this.game.seat_id].hand.selectCursorSwapLeft();
      this.game.playSound('whoop2');
    }
    this.swapLeftBtn = new GameButton({scene: this,
      x: this.game.config.width - 240 - btnSize,
      y: this.game.config.height - 150,
      w: btnSize,
      h: btnSize,
      text: `↶`,
      font: btnFont,
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.swapLeftBtnClick,
      clickFunctionData: {},
    });
    this.swapRightBtnClick = () => {
      this.checkPlayerRecommendedNextAction({});
      this.seats[this.game.seat_id].hand.selectCursorSwapRight();
      this.game.playSound('whoop2');
    }
    this.swapRightBtn = new GameButton({scene: this,
      x: this.game.config.width - 60 - btnSize,
      y: this.game.config.height - 150,
      w: btnSize,
      h: btnSize,
      text: `↷`,
      font: btnFont,
      radius: 16,
      visible: btnVisibleDefault,
      alpha: .4,
      clickFunction: this.swapRightBtnClick,
      clickFunctionData: {},
    });

    this.keyboard = Phaser.Input.Keyboard;
    this.keyMap = {
      enter: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER),
      down:  this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN),
      up:    this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP),
      left:  this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT),
      right: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT),
      z:     this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Z),
      x:     this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.X),
      s:     this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S),
      q:     this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
      c:     this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.C),
    }

    //////////// playing around with masking
    // this.highlightLayer = this.add.layer();
    // this.highlightBgObj = new Phaser.GameObjects.Graphics(this);
    // this.highlightBgObj.fillStyle(0x000000, 1); // 0x5c3709 // 0x3e1baf
    // this.highlightBgObj.fillRoundedRect(0, 0, this.game.config.width, this.game.config.height, 0);
    // this.highlightBgObj.setAlpha(.75);
    // this.add.existing(this.highlightBgObj);
    // this.highlightObj = new Phaser.GameObjects.Graphics(this);
    // this.highlightObj.fillStyle(0xffffff, 1);
    // this.highlightObj.fillCircle(this.game.config.width / 2, this.game.config.height / 2, 300);
    // this.highlightMask = this.highlightObj.createGeometryMask();
    // this.highlightMask.invertAlpha = true;
    // this.highlightLayer.setMask(this.highlightMask);
    // this.highlightLayer.add(this.highlightBgObj);
    //////////// (END) playing around with masking

    // // // ws fun
    // this.wsinfo.wsurl = Capacitor.getPlatform() == 'web' ? "wss://api.avalanche.dev.decknroll.com" : 'wss://api.avalanche.dev.decknroll.com'; //'wss://10.0.2.2:8010'; // 10.0.2.2 uses host local for android (tbd ios testing)
    // this.wsinfo.wsurl = Capacitor.getPlatform() == 'web' ? "wss://api.local.dev.decknroll.com:8010" : 'wss://api.local.dev.decknroll.com:8010'; //'wss://10.0.2.2:8010'; // 10.0.2.2 uses host local for android (tbd ios testing)
    this.wsinfo.wsurl = `wss://${this.wsinfo.host}`;

    // this isn't letting me get the token from the set-cookie header
    // this.wsinfo.getDnrToken = async function() {
    //   // 
    //   const options = {
    //     url: 'https://token.auth.decknroll.com/get',
    //     headers: {},
    //     method: 'GET',
    //     mode: "no-cors",
    //     credentials: "include",
    //     webFetchExtra: {
    //       credentials: "include",
    //     }
    //   };
    //   const response = await CapacitorHttp.request({ ...options })
    //   return response;
    // };

    // this.wsinfo.openWebSocketBtn = new GameButton({scene: this,
    //   x: 45,
    //   y: 165,
    //   w: 115,
    //   h: 115,
    //   text: `ws`,
    //   font: "45px Arial",
    //   radius: 45,
    //   visible: false,
    //   clickFunction: ()=>{
    //       this.debugMenu.text.setText('opening websocket...\n' + this.debugMenu.text.text);
    //       this.debugMenu.text.setText('wsurl=' + this.wsinfo.wsurl + '\n' + this.debugMenu.text.text);
    //       this.wsinfo.openWebSocket();
    //   },
    //   clickFunctionData: {},
    // });

    // this.wsinfo.googleAuthBtn = new GameButton({scene: this,
    //   x: 45,
    //   y: 165 + (130),
    //   w: 115,
    //   h: 115,
    //   text: `ga`,
    //   font: "45px Arial",
    //   radius: 45,
    //   visible: false,
    //   clickFunction: async ()=>{
    //       this.debugMenu.text.setText('starting google auth...\n' + this.debugMenu.text.text);
    //       await this.checkLoggedIn();
    //   },
    //   clickFunctionData: {},
    // });

    // this.wsinfo.sendUserIdTokenToWS = new GameButton({scene: this,
    //   x: 45,
    //   y: 165 + (130*2),
    //   w: 115,
    //   h: 115,
    //   text: `tws`,
    //   font: "45px Arial",
    //   radius: 45,
    //   visible: false,
    //   clickFunction: async ()=>{
    //       this.debugMenu.text.setText('sending google auth idtoken to websocket\nfor user id: ' + this.wsinfo.googleResponse.user.uid + '\n' + this.debugMenu.text.text);
    //       const idToken = await FirebaseAuthentication.getIdToken();
    //       this.wsinfo.sendTokenToWebSocket(idToken.token);
    //   },
    //   clickFunctionData: {},
    // });

    this.wsinfo.findSeat = async function() {
      const options = {
        // url: 'https://api.avalanche.dev.decknroll.com/find_seat',
        // url: 'https://api.local.dev.decknroll.com:8010/find_seat',
        url: `https://${self.wsinfo.host}/find_seat`,
        headers: {
          "Content-Type": "application/json"
        },
        data: { dnr_token: JSON.parse(await self.game.dnrConfig.get("dnrToken")) },
        method: 'POST',
        // mode: "no-cors",
      };
      // console.log(options);
      try {
        // console.log("findSeat options", options);
        const response = await CapacitorHttp.request(options);
        // console.log("findSeat response", response);
        return response.data.id;
      }
      catch (e) {
        const pit = new PopInText({
          scene: self,
          x: self.game.config.width / 2,
          y: self.game.config.height / 2,
          text: `There was a problem connecting\nto the game server.\n\nTrying again...`,
          box: {w: 1250, h: 575, radius: 30},
          startScale: .65,
          clickFunction: () => { cleanupScene() },
          endScale: .75,
          scaleBy: .9,
          duration: 200,
          onComplete: async ()=>{
            pit.closeTextUpdater({ runForSeconds: 3, text: 'click to cancel', endCallback: async ()=>{ await self.wsinfo.findSeat() } });
          },
          // onDestroy like this is not friendly with scene stopping (like clicking the leave current game button) and crashes the page
          // onDestroy: async ()=>{ 
          //   // if (self.scene.isActive('PlayGame')) {
          //   //   console.log('ISACTIVE!!!')
          //   //   // await self.wsinfo.findSeat();
          //   // }
          // },
          font: "60px Arial Black",
        })
        return null;
      }
      // return (response && response.data && response.data.id) ? response.data.id : null;
    }

    this.wsinfo.openWebSocket = function(tableId) {
      // console.log('tdoWS', this); // this is this.wsinfo(from scene)
      this.temp.socket = new WebSocket(`${this.wsurl}/${tableId}`);
      this.temp.socket.onopen = async function (e) {
        // console.log('onopen', e);
        self.showGameId(tableId);
        self.wsinfo.sendTokenToWebSocket(JSON.parse(await self.game.dnrConfig.get("dnrToken")));
      };
      this.temp.socket.onclose = function (e) {
        // console.log('onclose', e);
      }
      this.temp.socket.onmessage = function (event) {
        // console.log('<<< event', event);
        const payload = JSON.parse(event.data)
      // console.log('AAAAA', payload);
        // console.log('tdoWS ttsom payload', payload);
        switch (payload.state) {
          case 'table':
            // self.leaveCurrentGameBtn.setVisible(true);
            self.checkStateTable(payload);
            break
          case 'game':
            self.checkStateGame(payload);
            break
        }
        switch (payload.event) {
          case 'ready':
            self.markPlayerInSeatReady(payload.id);
            break
          case 'reoccupy_seat':
          case 'occupy_seat':
            self.addSeat(payload);
            break
          case 'empty_deck':
            break
          case 'start':
            break
          case 'start_countdown':
            self.readyPlayers();
            self.gameInProgress = true;
            self.leaveCurrentGameBtn.setVisible(true);
            self.startCountDown('Starting in', payload.seconds);
            break
          case 'discard':
          case 'discard_pile': // AKA first card
            self.discardCard(payload);
            break
          case 'dp_to_dq':
            self.discardPileToQueue();
            break
          case 'dq_to_dp':
            self.discardQueueToDiscardPile(payload);
            break
          case 'pull_player':
          case 'pull':
            self.pullCard(payload);
            break
          case 'disconnected':
            // console.log('disconnected payload', payload);
            self.playerDisconnected(self.getSeat(payload.seat_id), payload.will_vacate_in_ms);
            break
          case 'vacated':
            self.vacateSeat(self.getSeat(payload.seat_id));
            break
          case 'draw_player':
          case 'draw':
            self.showAllPlayButtons(true);
            // console.log('drawCards call::by draw::', payload);
            self.drawCards(payload);
            break
          case 'turn': // changing player turns
            self.changeTurn(payload);
            break
          case 'end':
            self.endGame(payload);
            break
          case 'failed_discard':
            self.failedDiscard(payload);
            break
          case 'points_deducted':
            // TODO build this
            self.deductPoints(payload)
            // id(seat id), points(total points), points_per(card)
            // for the seat id, show the total points with a minus sign deducted on the plaayer it is for, and in red font color
            // maybe for the local player each card from left to right is quickly raised and the points_per is shown (e.g. -20)
            break
          case 'replenish_deck':
            self.replenishDrawPile(payload.leave_in_discard_pile)
        }
        if (payload.cmd_out) {
          // console.log('<< onmessage cmd_out', payload);
          if (payload.out?.avail_actions) {
            self.setButtonsPerAvailActions(payload.out?.avail_actions);
          }
        }
      };
      // console.log(this.temp.socket);
    }

    // gg.dev.googleResponse.authentication.idToken
    this.wsinfo.sendTokenToWebSocket = (t) => {
      const cmd = {cmd: 'user', dnr_token: t};
      this.wsinfo.sendCmd(cmd);
    }

    this.wsinfo.sendCmd = (cmd) => {
      // {cmd: 'discard', card_id: card.id}
      if (typeof cmd == 'string') { cmd = { cmd: cmd } }
      const stringifiedCmd = JSON.stringify(cmd);
      // console.log(`Sending: ${stringifiedCmd}`);
      this.wsinfo.temp.socket.send(stringifiedCmd);
      // this.socket.send(`{"cmd": "${cmd}"}`);
    }
    const updateGameSize = ()=>{
      // reposition elements and change gameSize
      this.time.delayedCall(1, ()=>{
        // console.log('delayedCall1', this.game.getMainWidth());
        this.game.setGameSize(this, (scene) => {
          // console.log('delayedCall2', this.game.getMainWidth());
          // console.log('callback of sGZ', scene.game.positionConfig.playerInfo.x, scene.game.positionConfig.playerInfo.y);
          scene.playerInfo.setPosition(scene.game.positionConfig.playerInfo.x, scene.game.positionConfig.playerInfo.y);
          scene.introMessage.setPosition(scene.game.positionConfig.introMessage.x, scene.game.positionConfig.introMessage.y);
          scene.playOnlineButton.setPosition(scene.game.positionConfig.playOnlineButton.x, scene.game.positionConfig.playOnlineButton.y);
          scene.backToGameSelectionButton.setPosition(scene.game.positionConfig.backToGameSelectionButton.x, scene.game.positionConfig.backToGameSelectionButton.y);
          scene.leftControlsContainer.setPosition(scene.game.positionConfig.leftControlsContainer.x, scene.game.positionConfig.leftControlsContainer.y)
          scene.leftControlsContainer.setScale(scene.game.positionConfig.leftControlsContainer.scale);
          // scene.deck64Logo.setPosition(scene.game.positionConfig.deck64logo.x, scene.game.positionConfig.deck64logo.y);
          if (!window.showLandscape()) { // change the background for portrait mode
            // console.log('!window.showLandscape()', !window.showLandscape(), scene.bgContainer)
            scene.bgContainer.setRotation(Math.PI / 2);
            scene.bgContainer.setX(scene.getWidth());
          }
          else {
            // console.log('window.showLandscape()', window.showLandscape(), scene.bgContainer)
            scene.bgContainer.setRotation(0);
            scene.bgContainer.setY(0);
            scene.bgContainer.setX(0);
            scene.bgContainer.list[0].setScale(Math.max(scene.getWidth() / scene.bgContainer.list[0].width, scene.getHeight() / scene.bgContainer.list[0].height));
          }
        });
        
      });
    }
    this.scale.on('orientationchange', updateGameSize);
    window.addEventListener('resize', updateGameSize);
    updateGameSize();
  }

  showGameId(id) {
    let t = new Phaser.GameObjects.Text(this, 10, this.game.config.height - 15, `game:${id}`, {font: "32px Arial", color: "#cccccc", boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    t.setShadow(3, 3, "#000000", 3, true);
    t.setOrigin(0, 1);
    t.setPadding(1);
    t.setScale(.4);
    this.add.existing(t);
    this.gameIdText = t;
  }

  // use this.seatPositions for relative positioning since "seat position matters"
  // they get updated in checkStateTable
  seatPositions = {
    0: null,
    1: null,
    2: null,
    3: null
  };

  isOnline() {
    return (this.wsinfo.temp.socket != null)
  }

  /**
   * Called when a 'end' event comes in on the websocket
   * @param {{winner: string, detail: string, result: string}} payload
   * winner = seat id  
   * result = win | tied  
   * TODO score?
   */
  endGame(payload) {
    // announce who won
    // calculate points of your own score
    // reset table for next game
    this.gameInProgress = false;
    this.announceWinner(this.getSeat(payload.winner));
    this.time.delayedCall(500, ()=>{
      this.addPoints({points: payload.points, seatId: payload.winner, callback: ()=>{
        this.moveScoreToTotalPoints();
      }});
    });
  }

  playerDisconnected(seat, will_vacate_in_ms) {
    seat.disconnected = new PopInText({
      scene: this,
      x: this.game.config.width * seat.seatPosition.x,
      y: this.game.config.height * seat.seatPosition.y,
      text: `Player\nDisconnected`,
      startScale: .4,
      scaleBy: .8,
      duration: will_vacate_in_ms
    });
    seat.showCheckMark(false);
  }

  vacateSeat(seat) {
    seat && seat.vacate()
  }

  showLoadingAnimation(enable=true) {
    // console.log('showLoadingAnimation', enable);
    if (enable) {
      this.loadingAnimCard = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, `${this.game.gameDeckFace}-back-${this.game.gameDeckBack}`);
      this.loadingAnimCard.setScale(.75); 
      this.loadingAnimCard.setDepth(10000000000); // above all
      this.loadingAnimCardTween =this.tweens.add({
        targets: this.loadingAnimCard,
        angle: 360,
        duration: 4000,
        repeat: -1,
        ease: 'Linear'
      }).play();
    }
    else {
      this.loadingAnimCard.destroy();
    }
  }

  /**
   * Called when a 'points_deducted' event comes in on the websocket
   * @param {{id: number, points: number, points_per: number} } payload 
   */
  deductPoints(payload) {
    // console.log('dP', payload, this.seats[payload.id]) ;
    const pit = new PopInText({
      scene: this,
      x: this.seats[payload.id].x,
      y: this.seats[payload.id].y,
      text: `-${payload.points}`,
      startScale: .5,
      fontColor: "#e20d0d",
      box: {w: 460, h: 190, radius: 20},
      closeText: `${payload.points_per} points per card in your hand`,
      endScale: .75,
      scaleBy: .9,
      duration: 300,
      // onStart: ()=>{
      //   this.playerInfo.scoreText.text = 0;
      // },
      onComplete: ()=>{
        this.time.delayedCall(3000, () => {
          // console.log('dP onComplete timeout', pit)
          pit.destroy();
        });
      },
      font: "200px Arial Black",
    })
  }

  /**
   * Called when a 'failed_discard' event comes in on the websocket
   * @param {{card_id: string, id: string}} payload
   */
  failedDiscard(payload) {
    // flag the card asap
    // console.log("fD failedDiscard", payload);
    const hand = this.getSeat(payload.id).hand;
    const isPlayer = payload.id == this.game.seat_id;
    this.game.playSound('discardfail');
    // console.log("fD isPlayer", isPlayer)
    const postCall = () => {
      this.time.delayedCall(1000, ()=>{
        // console.log("fD postCall for ");
        const idx = this.failedDiscardPile.getIdxByCardId(`${this.game.gameDeckFace}-${payload.card_id}`);
        if (idx > -1) {
          this.failedDiscardPile.moveCardToCardSet({ toCardSet: hand, idx: idx, faceUp: isPlayer, style: 'yank', spin: -2 });
          // console.log(`fD discarded failedDiscard for ${this.game.gameDeckFace}-${payload.card_id}`);
        } else {
          // console.log(`fD could not find index for ${this.game.gameDeckFace}-${payload.card_id}`);
        }
      });
    }
    if (isPlayer) { // failed discard for the current player
      this.setButtonsPerAvailActions(payload.avail_actions);
      if (payload.no_action_for) {
        hand.noActionAllowed = true;
        this.time.delayedCall(payload.no_action_for, () => {
          hand.noActionAllowed = false;
        });
      }

      const idx = this.discardQueue.getIdxByCardId(`${this.game.gameDeckFace}-${payload.card_id}`);
      if (idx > -1) {
        delete this.discardQueue.list[idx].inTransit;
        this.discardQueue.moveCardToCardSet({
          toCardSet: hand,
          faceUp: true,
          style: 'drop',
          // postCall: postCall,
          spin: 2,
          delay: 1000,
          idx: idx,
        });
      } else {
        // console.log(`fD could not find index for ${payload.card_id}`);
      }
    } else { // failed discard for an opponent
      hand.moveCardToCardSet({ toCardSet: this.failedDiscardPile, postCall: postCall, cardId: `${this.game.gameDeckFace}-${payload.card_id}`, spin: 2, faceUp: true, style: 'drop' });
    }
  }

  /**
   * Called when 'dp_to_dq' event comes in on the websocket
   */
  discardPileToQueue(callback) {
    if (this.discardQueue.list.length == 0) {
      this.discardPile.moveCardToCardSet({
        toCardSet: this.discardQueue,
        delay: 0,
        startSound: false,
        duration: 100,
        faceUp: true,
        style: 'toss',
        postCall: () => {
          // seat.hand.selectCursorSet({ idx: 0 });
          // actualDiscard();
          callback && callback.call(this);
        }
      });
    }
  }

  /**
   * Called when 'dq_to_dp' event comes in on the websocket
   * @param {{id: string, points: string, points_per: string}} payload
   * id = seat id that triggered the event  
   * points = total points for that player  
   * points_per = points given per card  
   */
  discardQueueToDiscardPile(payload) {
    if (this.discardQueue.list.length > 0 ) {
      let cardsPlayed = this.discardQueue.list.length - 1;
      let points = payload.points
      this.discardQueue.moveAllCardsToCardSet({
        toCardSet: this.discardPile,
        delay: 200,
        dynamicDiscardSounds: true,
        postCall: () => {
          // revist this once we have one attribute bonus and/or points in the API
          if (points > 0) {
            // TODO use this once we have points really coming from the API and fix this.addPoints to 
            this.addPoints({points: points, cardsPlayed: cardsPlayed, seatId: payload.id});
          }
        },
        moveArguments: {
          faceUp: true,
          spin: 0,
          duration: 300,
          style: 'toss',
          startSound: false,
        }
      });
    }
  }

  /**
   * Called when a 'discard' or 'discard_pile' event comes in on the websocket
   * @param {{card_id: string, id: string | undefined}} payload
   */
  discardCard(payload) {
    // console.log('dC', payload);
    const fromCardSet = (payload.id != null) ? this.seats[payload.id].hand : this.deck;
    const actualDiscard = () => {
      fromCardSet.moveCardToCardSet({
        toCardSet: this.discardQueue,
        // delay: tweenDelay, 
        flipDelay: 20,
        cardId: `${this.game.gameDeckFace}-${payload.card_id}`,
        cardAttributes: Card.parseCardIdToAttributes(payload.card_id),
        flipDuration: this.game.gameSpeed.discardQueueMoveDuration / 2,
        duration: this.game.gameSpeed.discardQueueMoveDuration,
        faceUp: true,
        style: 'toss',
        addHandlerDelay: 0,
      });
    };
    if (this.discardQueue.list.length == 0 && payload.event == 'discard') {
      this.discardPile.moveCardToCardSet({
        toCardSet: this.discardQueue,
        delay: 0,
        startSound: false,
        duration: 100,
        faceUp: true,
        style: 'toss',
        postCall: () => {
          // seat.hand.selectCursorSet({ idx: 0 });
          actualDiscard();
        }
      });
    }
    else {
      actualDiscard();
    }
  }
  /**
   * 
   * Called when a 'pull' event comes in on the websocket
   * @param {{card_id: string, id: string}} payload
   */
  pullCard(payload) {
    const seatId = payload.id != null ? payload.id : this.game.seat_id;
    const seat = this.seats[seatId];
    let idx = this.discardQueue.getIdxByCardId(payload.card_id); // we can probably do without this, but if there's some odd latency it could be easier to use the index
    // ... otherwise the discardQueue could get out of sync on some clients
    this.discardQueue.moveCardToCardSet({ toCardSet: seat.hand, idx: idx, delay: 50, flipDelay: 350, flipDuration: 300, faceUp: this.localPlayerTurn, style: 'default' } );
    if (this.localPlayerTurn) {
      // this.configurePullBtn();
      this.setButtonsPerAvailActions(payload.avail_actions);
    }
    this.checkPlayerRecommendedNextAction({seatId: payload.id});
  }

  setButtonsPerAvailActions(availActions) {
    // console.log('sBPAA availActions = ', availActions);
    (availActions.indexOf(PlayGame.availActions.DISCARD) > -1) ? (this.playBtn.disable(false)) : (this.playBtn.disable(true));
    (availActions.indexOf(PlayGame.availActions.PULL) > -1) ? (this.pullBtn.disable(false)) : (this.pullBtn.disable(true));
    (availActions.indexOf(PlayGame.availActions.DRAW) > -1) ? (this.drawBtn.disable(false)) : (this.drawBtn.disable(true));
    (availActions.indexOf(PlayGame.availActions.ENDTURN) > -1) ? (this.endTurnBtn.disable(false)) : (this.endTurnBtn.disable(true));
  }
  
  /**
   * 
   * Called when a 'turn' event comes in on the websocket
   * @param {{start_id: string, end_id: string|undefined, can_draw: true}} payload
   */
  changeTurn({start_id, end_id=-1, can_draw=true, time_limit=30}) {
    // console.log("cT", start_id, end_id, time_limit);
    this.localPlayerTurn = this.game.seat_id == start_id;
    if (end_id > -1) {
      this.seats[end_id].highlightSeat(false);
      this.seats[end_id].turnTimeLimitProgressBar.setVisible(false);
    }
    this.seats[start_id] && this.seats[start_id].highlightSeat(true);
    if (this.localPlayerTurn) {
      this.drawBtn.disable(!can_draw);
      this.game.playSound('yourturn');
      this.playerMustDraw = can_draw;
      this.turnTimeLimitProgressBar.setVisible(true);
      this.turnTimeLimitProgressBar.setProgress({perc: 0, duration: time_limit * 1000, reset: true});
    }
    else {
      this.turnTimeLimitProgressBar.setVisible(false);
      this.seats[start_id] && this.seats[start_id].turnTimeLimitProgressBar.setVisible(true) && this.seats[start_id].setTimeLimit({perc: 0, duration: time_limit * 1000, reset: true});
    }
    // this.leaveCurrentGameBtn.disable(false);
    // this.configureDrawBtn();
    this.configureEndTurnBtn();
    if (this.seats[start_id] && !this.seats[start_id].hand.hasCardSelected()) { // select first card if none selected already
      this.seats[start_id].hand.selectCursorSet({});
    }
    this.configurePlayAndArrowBtns({seatId: start_id});
    
    this.checkPlayerRecommendedNextAction({seatId: start_id});
  }

  checkStateTable(payload) {
    // console.log("cST payload", payload);
    const selfSeat = payload.seats.find(({ player_id }) => player_id === this.game.playerId);
    // console.log("cST selfSeat", selfSeat);
    if (selfSeat) { // local player seat found
      function increaseId(id) {
        // console.log('cST.iI id', id);
        return (id == 3) ? 0 : ++id;
      }
      // let's make all other seats relative to that player being on the bottom
      this.seatPositions[selfSeat.id] = Seat.playerBottomPosition;
      let nextId = increaseId(selfSeat.id);
      this.seatPositions[nextId] = Seat.playerLeftPosition;
      nextId = increaseId(nextId);
      this.seatPositions[nextId] = Seat.playerTopPosition;
      nextId = increaseId(nextId);
      this.seatPositions[nextId] = Seat.playerRightPosition;
    }
    payload.seats.forEach((seat) => {
      // console.log("checkStateTable() forEach seat", seat);
      this.addSeat(seat);
    })
    this.showLoadingAnimation(false);
  }


  checkStateGame(payload) {
    // console.log("cSG", payload);
    const selfSeat = payload.seats.find(({ player_id }) => player_id === this.game.playerId);
    if (selfSeat) {
      this.game.seat_id = selfSeat.id;
    }
    let gameNeedsAllPlayersToReadyUp = false;
    if (payload?.avail_actions?.indexOf("ready") > -1) {
      gameNeedsAllPlayersToReadyUp = true;
    }
    payload.seats.forEach((seat) => {
      // console.log("checkStateGame() seat", seat);
      this.addSeat(seat);
      if (!gameNeedsAllPlayersToReadyUp) {
        // console.log('drawCards call::by checkStateGame::', seat);
        this.drawCards({...seat, avail_actions: payload.avail_actions});
      }
      if (seat.ready) {
        this.getSeat(seat.id).checkMark(true);
      }
      if (seat.score) {
        // console.log('cSG seat.score', seat.score, 'id', seat.id);
        this.getSeat(seat.id).setScore(seat.score);
        // console.log('cSG seat.score after setScore', this.getSeat(seat.id).score, 'id', seat.id);
      }
    })
    if (!gameNeedsAllPlayersToReadyUp) {
      if (selfSeat) {
        this.readyBtn?.destroy();
        this.playerMustDraw = payload.avail_actions.indexOf(PlayGame.availActions.DRAW) > -1; // selfSeat.can_draw;
        this.showAllPlayButtons(true);
        if (selfSeat.hand.card_ids.length > 0) {
          this.seats[this.game.seat_id].hand.selectCursorSet({});
        }
        this.playerInfo.scoreText.text = selfSeat.score;
        this.configurePlayAndArrowBtns({seatId: selfSeat.id});
      }
      this.readyPlayers();
      payload.discard_pile.card_ids.forEach((cardId) => {
        // console.log(cardId)
        this.deck.moveCardToCardSet({
          toCardSet: this.discardPile, cardId: `${this.game.gameDeckFace}-${cardId}`, faceUp: true, style: 'drop'
        });
      });
      payload.discard_queue.card_ids.forEach((cardId) => {
        // console.log(cardId)
        this.deck.moveCardToCardSet({
          toCardSet: this.discardQueue, cardId: `${this.game.gameDeckFace}-${cardId}`, faceUp: true, style: 'drop'
        });
      });
      this.changeTurn({
        start_id: payload.current_turn_seat_id, 
        can_draw: (selfSeat ? payload.avail_actions.indexOf(PlayGame.availActions.DRAW) > -1 : false)
      });
      // this.configureEndTurnBtn();
      // this.configureDrawBtn();
      // this.configurePlayAndArrowBtns({seatId: selfSeat.id});
      // this.configurePullBtn();
      this.gameInProgress = true;
    }
  }

  // online mode
  drawCards(payload) {
    // console.log('dC payload', payload);
    const scene = this;
    const seat = this.getSeat(payload.id);
    const drawPile = this.deck; // fromDrawDeck ? this.deck : seat.drawPile;
    const hand = seat.hand;
    // console.log(payload.id);
    // if (payload.draw_pile_size > 0 && playerDrawPile.list.length == 0) { // only do this when the player draw pile is empty (mainly the first time)
    //   for (let i = 0; i < payload.draw_pile_size; i++) {
    //     scene.deck.moveCardToCardSet({ toCardSet: playerDrawPile, faceUp: false, style: 'default' } );
    //   }
    // }
    // console.log('dC payload', payload);
    let card_ids = payload.card_ids ?? payload.hand?.card_ids;
    // console.log('dC card_ids', card_ids);
    let num_cards = payload.num_cards ?? payload.hand?.num_cards;
    // console.log('dC num_cards', num_cards);
    if (card_ids) {
      // console.log("dC time to deal to local player");
      this.setButtonsPerAvailActions(payload.avail_actions);
      // TODO remove following if above works
      // if (payload.can_draw) {
      //   this.playerMustDraw = true;
      // }
      // else {
      //   this.playerMustDraw = false;
      // }
      // this.endTurnBtn.disable(!payload.can_end_turn);
      // this.configureEndTurnBtn();      
      card_ids.forEach((cardId) => {
        // console.log('dC parsed card attributes', Card.parseCardIdToAttributes(cardId));
        drawPile.moveCardToCardSet({ toCardSet: hand, cardId: `${scene.game.gameDeckFace}-${cardId}`, delay: 88, faceUp: true, style: 'default' } );
      });
    } else if (num_cards) {
      // console.log(`dC time to deal to opponent ${payload.id}`); 
      for (let i = 0; i < num_cards; i++) {
        drawPile.moveCardToCardSet({ toCardSet: hand, delay: i * 91, style: 'default' } );
      };
    }
  }

  startCountDown(text, seconds) {
    let totalSeconds = seconds
    for (let i = 0; i < totalSeconds; i++) {
      this.time.delayedCall(i*1000, ()=>{
        new PopInText({
          scene: this,
          x: this.getWidth() * .5,
          y: this.getHeight() * .5,
          text: `${text} ${totalSeconds - i}...`,
          startScale: .65,
          scaleBy: 2,
          endScale: .3,
          duration: 450,
          font: "110px Arial Black",
        });
      });
    }
  }

  readyPlayers() {
    Object.values(this.seats).forEach((seat) => {
      seat.showCheckMark(false); // Hide all the check marks for players
      seat.setPlayingPosition(); // move the seat/player objects towards the edges
    })
    this.playerInfo.scoreVisible(true);
    // this.backToPlayGameStartButton.setVisible(false); // TODO confirm this works
  }

  setupReadyButton() {
    const btnDims = {w: 260, h: 110, r: 65};
    this.readyBtn = new GameButton({scene: this ,
      x: (this.getWidth() / 2) - btnDims.w / 2,
      y: (this.getHeight() / 2) - btnDims.h / 2,
      w: btnDims.w,
      h: btnDims.h,
      text: `Ready`,
      font: '65px Arial',
      radius: btnDims.r,
      borderWidth: 8,
      clickFunction: ()=>{
        this.readyBtn?.destroy();
        this.wsinfo.sendCmd("ready");
        // this.backToPlayGameStartButton.setVisible(true);
        this.markPlayerInSeatReady(this.game.seat_id);
      },
      clickFunctionData: {}
    });
  }

  getSeat(seatId) {
    // console.log('getSeat seatId', seatId);
    // let seat = this.seats.find(p => p.id == seatId); // not an array anymore
    let seat = this.seats[seatId];
    return seat;
  }

  markPlayerInSeatReady(seatId) {
    this.getSeat(seatId).checkMark(true);
  }

  addSeat(data) {
    // console.log('addSeat data', data);
    const scene = this;
    let foundSeat = this.getSeat(data.id);
    let name = (!data.name) ? "Guest" : data.name;
    if (foundSeat) {
      if (foundSeat.disconnected) {
        foundSeat.disconnected.destroy();
      }
      // console.log(`addSeat() found seat for ${data.id}`,);
      foundSeat.occupySeat(name, this.game.getAvatarObjFromAvatarKey(data.avatar || data.seat?.avatar).avatar, !scene.gameInProgress, data.level);
      if (data.seat?.ready) {
        foundSeat.checkMark(true);
      }
      if (data.current_turn_seat_id && (data.current_turn_seat_id > -1)) {
        let currSeat = scene.getSeat(data.current_turn_seat_id)
        if (currSeat) {
          currSeat.highlightSeat(true);
        }
      }
      return
    }; // if player has a seat already then do nothing
    // console.log("Adding seat", data);
    let currentSeat = null;
    const isPlayer = scene.game.playerId == data.player_id
    // console.log('aS scene.game.playerId', scene.game.playerId);
    // console.log('aS data.player_id', data.player_id);
    // console.log('aS isPlayer', isPlayer);
    let avatar = null;
    if (isPlayer) {
      scene.game.seat_id = data.id;
      currentSeat = Seat.playerBottomPosition;
      // set avatar for current player
      avatar = scene.game.humanPlayer.avatar;
      !data.ready && this.setupReadyButton();
    } else {
      currentSeat = this.seatPositions[data.id]; // this.seatPositionIndex++];
      avatar = this.game.getAvatarObjFromAvatarKey(data.avatar || data.seat?.avatar).avatar;
      // avatar = getRandomAvatar();
    }
    // console.log('aS currentSeat', currentSeat);
    // console.log('aS avatar and data.id', avatar, data.id);
    let x = this.game.config.width * currentSeat.x;
    let y = this.game.config.height * currentSeat.y;
    let seat = new Seat({scene: this, x: x, y: y, text: name, imageKey: avatar, level: data.level, seatPosition: currentSeat, id: data.id});
    
    seat.occupySeat(name, avatar, true, data.level);
    // TODO confirm we need to call updateAvatar (redundant?)
    // this.updateAvatar(seat, data);
    this.seats[data.id] = seat;
    if (data.ready) {
      this.markPlayerInSeatReady(data.id);
    }
    if (scene.gameInProgress) {
      seat.showCheckMark(false); // Hide all the check marks for player
      seat.setPlayingPosition(); // move the seat/player objects towards the edges
      // console.log(data, data.seat);
      // console.log('drawCards call::by addSeat::', data.seat);
      this.drawCards(data.seat);
    }
    if (data.current_turn_seat_id && (data.current_turn_seat_id > -1)) {
      let currSeat = scene.getSeat(data.current_turn_seat_id)
      if (currSeat) {
        currSeat.highlightSeat(true);
        // secs_remaining = (
        //   self.seconds_per_turn - int(time.time() - self.turn_time_limit_timestamp)
        // )
      }
    }
  }

  update() {
    // move and highlight a menu (a table box)
    if (this.keyboard.JustDown(this.keyMap.down)) {
      this.pullBtnClick();
    }
    else if (this.keyboard.JustDown(this.keyMap.up)) {
      this.playBtnClick();
    }
    else if (this.keyboard.JustDown(this.keyMap.left)) {
      this.leftArrowBtnClick();
    }
    else if (this.keyboard.JustDown(this.keyMap.right)) {
      this.rightArrowBtnClick();
    }
    else if (this.keyboard.JustDown(this.keyMap.enter)) {
      if (this.gameInProgress) {
        this.endTurnBtnClick();
      }
      else {
        this.readyBtn.click();
      }
    // }
    // else if (this.keyboard.JustDown(this.keyMap.q)) {
    //   this.socket.close();
    //   this.scene.start('TableSelection');
    }
    else if (this.keyboard.JustDown(this.keyMap.z)) {
      this.swapLeftBtnClick();
    }
    else if (this.keyboard.JustDown(this.keyMap.x)) {
      this.swapRightBtnClick();
    }
    else if (this.keyboard.JustDown(this.keyMap.c)) {
      this.drawBtnClick();
    }
  }
  
  // async checkLoggedIn() {
  //   // const res = await GoogleAuth.signIn();
  //   // console.log('checkLoggedIn response:', res);
  //   // this.debugMenu.text.setText('checkLoggedIn...\n' + res.id + '\n' + this.debugMenu.text.text);
  //   // this.wsinfo.googleResponse = res;
  //   const result = await FirebaseAuthentication.signInWithGoogle();
  //   // console.log('fbase google res', result);
  //   this.wsinfo.googleResponse = result;

  //   // return result.user;

  // }

  loadCardsInDeck() {
    this.decks.forEach( (deck) => {
      PlayGame.cards.colors.forEach( (color) => {
        PlayGame.cards.numbers.forEach( (rank) => {
          PlayGame.cards.suits.forEach( (suit) => {
            const card = new Card({scene: this, card: ``, cardBack: `${deck.id}-back-${this.game.gameDeckBack}`, cardAttributes: {color: color, rank: rank, suit: suit}});
            // console.log(card, card.card)
            this.deck.addCard({card: card}); // create a deck of cards for a draw pile
          })
        })
      })
    });
  }

  // internally use to set this.humanPlayer without changing the visual human player (this.setHumanPlayer) 
  _setHumanPlayer(name) {
    let hp = PlayGame.computerPlayers.find(obj => {
      return obj.name === name;
    });
    this.humanPlayer = hp;
    return hp;
  }

  setHumanPlayer(name) {
    let humanPlayerObj = this.game.getAvatarObjFromAvatarKey(name); // this._setHumanPlayer(name);
    if (humanPlayerObj) {
      this.seats[this.game.seat_id].updateAvatar(humanPlayerObj.avatar);
    }
  }

  // options
  // not actual app settings but are per-game settings
  setGameDifficulty(level) {
    let gameDifficultyObj = PlayGame.gameDifficulties.find(obj => {
      return obj.level === level;
    });
    if (gameDifficultyObj) {
      this.gameDifficulty = gameDifficultyObj;
    }
  }

  setGameInitialCardsDealt(strNumber) {
    this.gameInitialCardsDealt = Number(strNumber);
  }

  setGameDeckReshuffles(str) {
    // console.log("start setGameDeckReshuffles(str)", str, this.gameDeckReshuffles);
    let num = Number(str);
    if (isNaN(num)) {
      num = PlayGame.BASE_VALUES[str];
    }
    this.gameDeckReshuffles = num;
    // console.log("end setGameDeckReshuffles(str)", str, this.gameDeckReshuffles);
  }

  setGameAudio(mode) {
    let gameAudioObj = PlayGame.gameAudio.find(obj => {
      return obj.mode === mode;
    });
    // console.log(gameAudioObj)
    if (gameAudioObj) {
      this.gameAudio = gameAudioObj;
    }
    if (mode == PlayGame.gameAudio[2].mode || mode == PlayGame.gameAudio[1].mode) {
      this.game.stopMusic();
    }
    else if (mode == PlayGame.gameAudio[0].mode) {
      this.game.playSound('d64-theme', true);
      // TODO replace all d64-theme hardcoded references
      //   with a game instance variable
    }
  }

  setGameDeckFace(deckName) {
    this.gameDeckFace = deckName;
    Object.values(this.seats).forEach((seat) => {
      this._setGameDeckFace(seat.hand);
    });
    // console.log('setGameDeckFace', this.gameDeckFace);
    [this.deck, this.discardPile, this.discardQueue].forEach((cs)=> {
      // console.log('cs', cs)
      this._setGameDeckFace(cs);
    });
  }

  _setGameDeckFace(cardset) {
    cardset && cardset.list.forEach((card)=>{
      // console.log(card, `setting to: ${this.gameDeckFace}-${card.cardAttributes.color}${card.cardAttributes.rank}${card.cardAttributes.suit}`)
      card.card = `${this.gameDeckFace}-${card.cardAttributes.color}${card.cardAttributes.rank}${card.cardAttributes.suit}`;
      card.faceUp && card.setTexture(card.card);
    });
  }

  // setGameSpeed(speed) {
  //   // console.log(`setGameSpeed ${speed}`)
  //   this.gameSpeed = PlayGame.gameSpeeds[speed];
  // }

  setGameDeckBack(t) { // main or alt
    this.gameDeckBack = t;
    Object.values(this.seats).forEach((seat) => {
      this._setGameDeckBack(seat.hand);
    });
    [this.deck, this.discardPile, this.discardQueue].forEach((cs)=> {
      this._setGameDeckBack(cs);
    });
  }

  _setGameDeckBack(cardset) {
    cardset && cardset.list.forEach((card)=>{
      // console.log('setting deck back', card)
      card.cardBack = `${this.gameDeckFace}-back-${this.gameDeckBack}`;
      !card.faceUp && card.setTexture(card.cardBack)
    });
  }

  // setGameTheme(themeName) {
  //   let gameThemeObj = { name: themeName, ...PlayGame.themes[themeName] };
  //   if (gameThemeObj) {
  //     this.gameTheme = gameThemeObj;
  //     this.bgContainer.removeAll(true);
  //     let bgImg = new Phaser.GameObjects.Image(this, this.game.config.width / 2, this.game.config.height / 2, gameThemeObj.background.key);
  //     bgImg.setDepth(-1000000);
  //     let newScale = Math.max(this.game.config.width / bgImg.width, this.game.config.height / bgImg.height); // determine if scaling should fit width or height. hint: use the larger ratio
  //     bgImg.setScale(newScale);
  //     bgImg.setAlpha(1);
  //     this.bgContainer.add(bgImg);
  //     // bgImg.setOrigin(0);
  //     // bgImg.setX(0);
  //     // bgImg.setY(0);
  //     // TODO update buttons and anything else during a theme change?
  //   }
  // }

  enableCardActionButtons(enable) {
    this.leftArrowBtn.disable(!enable);
    this.rightArrowBtn.disable(!enable);
    this.swapLeftBtn.disable(!enable);
    this.swapRightBtn.disable(!enable);
    this.playBtn.disable(!enable);
  }

  configurePullBtn() {
    if (this.localPlayerTurn && this.discardQueue.list.length > 1) {
      this.pullBtn.disable(false);
    }
    else {
      this.pullBtn.disable(true);
    }
    this.configurePlayAndArrowBtns({});
  }

  updateAvatar(keyRef) {
    this.seats && this.seats[this.game.seat_id] && this.seats[this.game.seat_id].updateAvatar(keyRef);
    this.playerInfo.avatar.setTexture(keyRef);
  }

  configureDrawBtn() {
    if (this.gameFinalTurns || this.discardQueue.list.length > 1 || !this.playerMustDraw) {
      this.drawBtn.disable(true);
    }
    else {
      if (this.localPlayerTurn) {
        this.drawBtn.disable(false);
      }
    }
  }

  configureEndTurnBtn() {
    if (this.localPlayerTurn) {
      if (this.gameFinalTurns || this.discardQueue.list.length > 1 || !this.playerMustDraw) {
        this.endTurnBtn.disable(false);
      }
      else {
        this.endTurnBtn.disable(true);
      }
    }
    else {
      this.endTurnBtn.disable(true);
    }
  }

  getWidth() {
    return this.cameras.main.width;
  }

  getHeight() {
    return this.cameras.main.height;
  }

  configurePlayAndArrowBtns({seatId = 0}) {
    if (this.seats[seatId] && this.seats[seatId].hand.hasCardSelected()) {
      if (seatId == this.game.seat_id) {
        this.swapLeftBtn.disable(false);
        this.swapRightBtn.disable(false);
        this.leftArrowBtn.disable(false);
        this.rightArrowBtn.disable(false);
      }
      if (!this.localPlayerTurn) {
        this.playBtn.disable(true);
      }
      else {
        this.playBtn.disable(false);
      }
    }
    else {
      this.playBtn.disable(true);
      this.leftArrowBtn.disable(true);
      this.rightArrowBtn.disable(true);
      this.swapLeftBtn.disable(true);
      this.swapRightBtn.disable(true);
    }
  }

  pullButtonClick() {
    if (!this.localPlayerTurn) { return 0; }
    if (this.discardQueue.list.length > 1) {
      if (this.isOnline()) {
        this.wsinfo.sendCmd('pull');
      }
      else {
        this.discardQueue.moveCardToCardSet({ toCardSet: this.seats[this.game.seat_id].hand, delay: 50, flipDelay: 350, flipDuration: 300, faceUp: true, style: 'default' } );
        this.checkPlayerRecommendedNextAction({});
      }
    }
  }

  playCardButtonClick() {
    if (!this.localPlayerTurn) { return 0; } // it's not the local player's turn so don't do anything
    if (this.seats[this.game.seat_id].hand.noActionAllowed) { return 0; } // temporary no action allowed (e.g. from a failed discard)
    if (this.isOnline()) {
      const seat = this.seats[this.game.seat_id];
      let cardId = seat.hand.list[seat.hand.selectCursorIdx];
      this.wsinfo.sendCmd({cmd: 'discard', card_id: `${cardId.cardAttributes.color}${cardId.cardAttributes.rank}${cardId.cardAttributes.suit}`});
    }
    if (this.discardQueue.list.length == 0) {
      this.discardPile.moveCardToCardSet({ toCardSet: this.discardQueue, startSound: false, faceUp: true, style: 'drop' });
    }
    this.seats[this.game.seat_id].hand.moveCardToCardSet({ toCardSet: this.discardQueue, faceUp: true, style: 'drop', postCall: (card, idx)=>{
      // console.log("playCardButtonClick():", card, idx);
      // console.log('playCardButtonClick()', )
      let dql = this.discardQueue.list.length - 1;
      // console.log('dql', dql);
      if (dql > 0 && dql <= 10) {
        // NativeAudio.play({
        //   assetId: `discard${dql}`,
        // });
        this.game.playSound(`discard${dql}`);
      }
      else if (dql > 10) {
        // NativeAudio.play({
        //   assetId: 'discardmax',
        // });
        this.game.playSound('discardmax');
      }
      this.checkPlayerRecommendedNextAction({});
    }});
  }

  endTurnClick() { // TODO maybe separate draw and end click function calls

  }

  addPoints({points, cardsPlayed = 0, callback=()=>{}, seatId = this.game.seat_id, pointsPerCard=15}) {
    const self = this;
    const pit = new PopInText({
      scene: this,
      x: this.game.config.width / 2,
      y: this.game.config.height / 2 - 40,
      text: `${points}`,
      startScale: 2,
      endScale: 0,
      scaleBy: .9,
      duration: 990,
      onComplete: ()=>{
        this.time.delayedCall(5, () => {
          pit.destroy();
        });
      },
      font: "76px Arial Black",
    })
    if (this.game.gameLearningMode.mode == 'on') {
      const name = (seatId == this.game.seat_id) ? 'You' : this.seats[seatId].text.text;
      if (cardsPlayed > 0) {
        let ppc = pointsPerCard;
        let pointsEarnedMessage = '';
        if (cardsPlayed > 1) {
          pointsEarnedMessage += `${name} played ${cardsPlayed} cards earning:\n`;
          pointsEarnedMessage += `${ppc}`;
          for (let i = 2; i <= cardsPlayed; i++) {
            if (i < 8) {
              pointsEarnedMessage += `+${i * ppc}`;
            }
            else if (i == 8) {
              pointsEarnedMessage += '...';
            }
          }
          pointsEarnedMessage += ` = ${points} points`;
        }
        else {
          pointsEarnedMessage += `${name} played 1 card to earn ${ppc} points.`
        }
        const pit2 = new PopInText({
          scene: this,
          x: this.game.config.width / 2,
          y: this.game.config.height - 190,
          text: `Each card earns a multiple of ${ppc} points.\n` +
                pointsEarnedMessage,
          box: {w: 1050, h: 175, radius: 30},
          startScale: .65,
          endScale: .75,
          scaleBy: .9,
          duration: 200,
          onComplete: ()=>{
            pit2.closeTextUpdater({ runForSeconds: 3 });
          },
          font: "60px Arial Black",
        })
      }
      else { // no cards played (points awarded via a win most likely)
        // TODO say something here?
      }
    }
    // move points text to seat (TODO add to actual score)
    this.tweens.chain({
      targets: [pit],
      tweens: [{
          // y: this.scoreText.y + 50,
          // x: this.playerInfo.scoreText.x - 60,
          y: this.seats[seatId].y,
          x: this.seats[seatId].x,
          ease: 'Expo',
          delay: 1000,
          duration: 1000,
          // repeat: 0,
          yoyo: false,
          onComplete: ()=> {
            this.seats[seatId].addScore(points);
            if (seatId == this.game.seat_id) {
              this.playerInfo.scoreText.text = Number(this.playerInfo.scoreText.text) + points;
              this.emitter.explode(5);
              this.tweens.add({
                targets: this.playerInfo.scoreText,
                scale: 1.5,
                duration: 300,
                ease: 'Bounce.easeIn',
                yoyo: true,
              });
            }
            callback.call(self);
          }
        }]
    }).play();
  }

  // setOneAttributeBonus({id=-1, resetCount=false}) {
  //   if (id > -1) {
  //     this.bonuses.oneAttributeMatch.playerId = id;
  //     this.bonuses.container.setVisible(true);
  //     this.bonuses.container.setAlpha(0);
  //     this.bonuses.container.x = this.game.config.width * this.seats[id].seatPosition.bonusIconX;
  //     this.bonuses.container.y = this.game.config.height * this.seats[id].seatPosition.bonusIconY;
  //     // console.log(`SOAB ${id}`);
  //   }
  //   if (resetCount) {
  //     this.bonuses.oneAttributeMatch.count = 0;
  //     // also set alpha to 0 but we don't need to here since setting the id above also sets it to 0
  //     // console.log(`SOAB reset count`);
  //   }
  //   this.bonuses.oneAttributeMatch.count++;
  //   this.bonuses.container.setAlpha(this.bonuses.container.alpha + (this.bonuses.container.alpha < 1 ? .25 : 0));
  //   // console.log(`SOAB count is now ${this.bonuses.oneAttributeMatch.count}`);
  // }

  // online mode
  drawCard() {
    // console.log('drawCard() sendCmd draw');
    this.wsinfo.sendCmd("draw");
  }

  // online mode
  endTurn() {
    this.localPlayerTurn = false;
    this.drawBtn.disable(true);
    this.playBtn.disable(true);
    this.pullBtn.disable(true);
    // console.log('endTurn() sendCmd end_turn');
    this.wsinfo.sendCmd("end_turn");
  }

  checkPlayerRecommendedNextAction({seatId = 0}) {
    if (this.localPlayerTurn && this.game.gameLearningMode.mode == 'on') {
      if (this.humanPlayerHint) {
        // clearTimeout(this.humanPlayerHint);
        this.time.removeEvent(this.humanPlayerHint);
      }
      this.humanPlayerHint = this.time.delayedCall(PlayGame.TIME_BETWEEN_HINTS, ()=>{
        if (this.localPlayerTurn) {
          let hasMatch = false;
          let topCard = (this.discardQueue.list.length > 0) ? this.discardQueue.getTopCard() : this.discardPile.getTopCard();
          let card = null;
          // const numRequiredMatchingAttributesOnFirstCard = this.bonuses.oneAttributeMatch.count >= Object.values(this.seats).length && this.bonuses.oneAttributeMatch.playerId == 0 && this.discardQueue.list.length <= 1 ? 1 : 2;
          const numRequiredMatchingAttributesOnFirstCard = 2;
          for (let i = 0;i<this.seats[seatId].hand.list.length;i++) {
            card = this.seats[seatId].hand.list[i];
            // console.log('checking card...', card);
            if (this.numAttributesMatching(topCard, card) >= numRequiredMatchingAttributesOnFirstCard) {
              hasMatch = true;
              break;
            };
          }
          if (hasMatch) {
            // recommend playing a card
            let cardIdx = this.seats[seatId].hand.getIdxByCardId(card.card);
            this.seats[seatId].hand.selectCursorSet({idx: cardIdx, reposition: true});
            const pit = new PopInText({
              scene: this,
              x: this.game.config.width / 2,
              y: this.game.config.height / 2,
              text: `You have a playable card.`,
              box: {w: 750, h: 175, radius: 30},
              startScale: .65,
              endScale: .75,
              scaleBy: .9,
              duration: 200,
              onComplete: ()=>{
                // setTimeout(()=>{
                //   pit.destroy();
                // }, 2000)
                pit.closeTextUpdater({ runForSeconds: 2 });
              },
              font: "76px Arial Black",
            })
          }
          else {
            if (this.discardQueue.list.length > 1 || !this.playerMustDraw) {
              // recommend end turn btn
              const pit = new PopInText({
                scene: this,
                x: this.game.config.width / 2,
                y: this.game.config.height / 2,
                text: 'You need to end your turn.',
                box: {w: 1250, h: 175, radius: 30},
                startScale: .65,
                endScale: .75,
                scaleBy: .9,
                duration: 200,
                onComplete: ()=>{
                  // setTimeout(()=>{
                  //   pit.destroy();
                  // }, 2000)
                  pit.closeTextUpdater({ runForSeconds: 2 });
                },
                font: "76px Arial Black",
              })
            }
            else {
              this.drawBtn.disable(false);
              this.drawBtn.select();
              if (this.game.gameLearningMode.mode == 'on' && this.deck.list.length > 0) {
                const pit = new PopInText({
                  scene: this,
                  x: this.game.config.width / 2,
                  y: this.game.config.height / 2,
                  text: 'You need to draw a card.',
                  box: {w: 1250, h: 175, radius: 30},
                  startScale: .65,
                  endScale: .75,
                  scaleBy: .9,
                  duration: 200,
                  onComplete: ()=>{
                    // setTimeout(()=>{
                    //   pit.destroy();
                    // }, 2000)
                    pit.closeTextUpdater({ runForSeconds: 2 });
                  },
                  font: "76px Arial Black",
                })
              }
            }
          }
        }
      });
    }
  }

  showAllPlayButtons(show) {
    this.leftArrowBtn.setVisible(show);
    this.rightArrowBtn.setVisible(show);
    this.endTurnBtn.setVisible(show);
    this.swapLeftBtn.setVisible(show);
    this.swapRightBtn.setVisible(show);
    this.pullBtn.setVisible(show);
    this.playBtn.setVisible(show);
    this.drawBtn.setVisible(show);
  }

  async startOnlineGame() {
    this.showLoadingAnimation();
    // let token = await this.game.dnrConfig.get("dnrToken");
    // console.log('sOG starting with token', token)
    if (await this.game.needsDnrToken()) {
      await this.game.getDnrToken();
      // token = await this.game.dnrConfig.get("dnrToken");
      // console.log('sOG with token:', token);
    }
    this.game.playerId = JSON.parse(await this.game.dnrConfig.get("playerId"));
    this.leaveCurrentGameBtn.setVisible(true);
    const tableId = await this.wsinfo.findSeat();
    // console.log('sOG tableId:', tableId);
    tableId && this.wsinfo.openWebSocket(tableId);
    // this.showAllPlayButtons(true); // TODO not yet - wait til all players ready up
  }

  numAttributesMatching(cardA, cardB) {
    return [cardA.cardAttributes.color == cardB.cardAttributes.color,
      cardA.cardAttributes.rank == cardB.cardAttributes.rank,
      cardA.cardAttributes.suit == cardB.cardAttributes.suit].filter(Boolean).length;
  }

  replenishDrawPile(cardsToLeave=2) {
    for (let i=this.discardPile.list.length-(1 + cardsToLeave);i>-1;i--) {
      this.discardPile.moveCardToCardSet({ toCardSet: this.deck, idx: i, delay: i * 50, flipDelay: (i * 50)+100, flipDuration: 200, faceUp: false, style: 'default' } );
    }
  }

  //////////////////////
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Memory_management
  // Will need to ensure the js heap memory isn't getting too high. page refresh after page refresh while dev'ing is causing the chrome tab to get into 3-4 GB of memory :(
  // quick check with:
  // >> performance.memory.usedJSHeapSize/performance.memory.jsHeapSizeLimit
  // >> 0.8425200694662263
  // TODO https://github.com/facebook/memlab
  //////////////////////

  // THIS WORKS :)
  recursiveChainBuilder(checkAgainstCard, cardSetList, numRequiredMatchingAttributesOnFirstCard = 2) {
    let cs = cardSetList.map(({card, cardAttributes}) => ({card, cardAttributes})); // card,color,rank,suit
    let chain = [];
    cs.forEach((card, idx) => {
      let match = this.numAttributesMatching(checkAgainstCard, card) >= numRequiredMatchingAttributesOnFirstCard;
      if (match) {
        let subChain = cs.splice(idx, 1);
        subChain.push(this.recursiveChainBuilder(card, cs));
        chain.push(subChain);
      }
    })
    // console.log('cs', cs);
    return chain;
  }

  // ch is a chain from recursiveChainBuilder(...)
  getLongestChain(ch) {
    if (ch.length > 0) {
      let currMax  = []
      let chain = []
      let arr = []
      if (Array.isArray(ch[0])) {
        arr = ch
      }
      else {
        chain = [ch[0].card]
        arr = ch[1]
      }
      arr.forEach((o,i)=>{
          let test = this.getLongestChain(o)
          if (test.length > currMax.length) {
            currMax = test
            // console.log('new currMax', currMax.length)
          }
      })
      return [...chain, ...currMax]
    }
    return []
  }

  moveCardsFromDQtoDP(callback, playerId) {
    this.discardQueue.moveAllCardsToCardSet({
      toCardSet: this.discardPile,
      delay: 200,
      // postCall: ()=>{
      //   this.setOneAttributeBonus({id: playerId, resetCount: true});
      // },
      moveArguments: {
        faceUp: true,
        spin: 0,
        duration: 300,
        depth: 5,
        style: 'toss',
        startSound: false,
      }
    });
    callback && callback.call(this, { oneAttributeBonusId: playerId });
  }

  // notifyBonusContainer() {
  //   this.tweens.chain({
  //     targets: [this.bonuses.container],
  //     tweens: [{
  //         y: this.bonuses.container.y - 150,
  //         scale: 1.5,
  //         ease: 'Expo',
  //         duration: 200,
  //         // repeat: 0,
  //         yoyo: true,
  //       },
  //       {
  //         y: this.bonuses.container.y - 70,
  //         scale: 1.25,
  //         ease: 'Bounce',
  //         // delay: 200,
  //         duration: 100,
  //         // repeat: 0,
  //         yoyo: true,
  //       }]
  //   }).play();
  // }

  announceWinner(seat) {
    const pit = new PopInText({
      scene: this,
      x: this.game.config.width * .5,
      y: this.game.config.height * .5,
      text: `${seat.text.text} won!`,
      box: {w: 850, h: 200, radius: 30},
      startScale: .65,
      endScale: .75,
      scaleBy: 1.5,
      font: "70px Arial Black",
      duration: 1300,
      onComplete: ()=>{
        pit.closeTextUpdater({ runForSeconds: 2 });
        this.time.delayedCall(2000, () => {
          pit.destroy();
        });
      }
    });

    // TODO animate confetti or something else fun here
    // points / count # of wins & persist local player record (wins/losses/draws)
    
    this.time.delayedCall(5000, this.resetGame, [], this);
  }

  moveScoreToTotalPoints() {
    const score = this.playerInfo.scoreText.text;
    this.playerInfo.scoreText.text = 0;
    const pit = new PopInText({
      scene: this,
      x: this.seats[this.game.seat_id].x,
      y: this.seats[this.game.seat_id].y,
      text: `${score}`,
      startScale: .5,
      endScale: 0,
      scaleBy: 5,
      duration: 1000,
      onComplete: ()=>{
        this.time.delayedCall(5, () => {
          pit.destroy();
        });
      },
      font: "76px Arial Black",
    })
    this.tweens.chain({
      targets: [pit],
      tweens: [{
          y: this.playerInfo.y + 50,
          x: this.game.config.width - 50,
          // x: this.playerInfo.x + 200,
          ease: 'Expo',
          delay: 1000,
          duration: 1000,
          // repeat: 0,
          yoyo: false,
          onComplete: ()=> {
            this.addToTotalPoints(score);
            // Number(this.playerInfo.scoreText.text) + points;
          }
        }]
    }).play();
  }

  addToTotalPoints(points) {
    this.game.gamePointsInCurrentLevel = Number(this.game.gamePointsInCurrentLevel) + Number(points);
    // this.game.dnrConfig.set("gamePointsInCurrentLevel", this.game.gamePointsInCurrentLevel);
    let pointsRequiredForLevelUp = this.game.gameCurrentLevel * PlayGame.levelUpFactor;
    this.game.gameCurrentLevelProgress = (this.game.gamePointsInCurrentLevel / pointsRequiredForLevelUp) * 100;
    this.playerInfo.levelProgressBar.setProgress({perc: Math.min(this.game.gameCurrentLevelProgress, 99), onComplete: () => {
      if (this.game.gamePointsInCurrentLevel >= pointsRequiredForLevelUp) {
        // console.log('GPRFLU', 1, pointsRequiredForLevelUp);
        this.game.gameCurrentLevel = Number(this.game.gameCurrentLevel) + 1;
        this.game.dnrConfig.set("gameCurrentLevel", this.game.gameCurrentLevel);
        this.game.gamePointsInCurrentLevel = this.game.gamePointsInCurrentLevel - pointsRequiredForLevelUp;
        // this.game.dnrConfig.set("gamePointsInCurrentLevel", this.game.gamePointsInCurrentLevel);
        this.playerInfo.levelText.text = this.game.gameCurrentLevel;
        this.emitter.explode(80);
        this.tweens.add({
          targets: this.playerInfo.levelText,
          scale: 1.5,
          duration: 400,
          ease: 'Bounce.easeIn',
          yoyo: true,
        });
        this.game.playSound('discard10');
        this.time.delayedCall(300, () => {
          this.game.playSound('discard7');
        });
        this.time.delayedCall(600, () => {
          this.game.playSound('discard8');
        });
        this.time.delayedCall(800, () => {
          this.game.playSound('discard9');
        });
        // console.log('GPRFLU', 3, this.game.gamePointsInCurrentLevel, pointsRequiredForLevelUp);
        // TODO animate celebration of level up
      }
      if (this.game.gamePointsInCurrentLevel >= pointsRequiredForLevelUp) {
        // console.log('GPRFLU', 4, this.game.gamePointsInCurrentLevel, pointsRequiredForLevelUp);
        this.playerInfo.levelProgressBar.setProgress({perc: 1, onComplete: ()=>{
          this.addToTotalPoints(0);
        }});
        
      }
      else {
        pointsRequiredForLevelUp = this.game.gameCurrentLevel * PlayGame.levelUpFactor;
        // console.log('GPRFLU', 2, pointsRequiredForLevelUp);
        this.game.gameCurrentLevelProgress = (this.game.gamePointsInCurrentLevel / pointsRequiredForLevelUp) * 100;
        this.playerInfo.levelProgressBar.setProgress({perc: this.game.gameCurrentLevelProgress});
      }
    }});
  }

  getHighestRankedCardInHand(arrayOfCards) {
    let highestCard = null;
    let card = null;
    for (let i = 0;i<arrayOfCards.length;i++) {
      card = arrayOfCards[i];
      // console.log('checking card...', card);
      if (highestCard) {
        // which is the highest rank card? naturally as the largest number, i.e. lowest to highest = 1, 2, 3, 4
        if (Number(card.cardAttributes.rank) > Number(highestCard.cardAttributes.rank)) { // rank(number) is higher
          highestCard = card;
        }
        else if (Number(card.cardAttributes.rank) == Number(highestCard.cardAttributes.rank)) { // rank(number) is the same
          // if tied on highest rank, which of those tied cards has the higher suit? lowest to highest = hearts, diamonds, clubs, spades
          if (PlayGame.cards.suits.indexOf(card.cardAttributes.suit) > PlayGame.cards.suits.indexOf(highestCard.cardAttributes.suit)) { // suit rank is higher
            highestCard = card;
          }
          else if (PlayGame.cards.suits.indexOf(card.cardAttributes.suit) == PlayGame.cards.suits.indexOf(highestCard.cardAttributes.suit)) { // suit rank is the same
            // if tied on highest suit, which of those tied cards has the higher color? lowest to highest = red, green, blue, black
            if (PlayGame.cards.colors.indexOf(card.cardAttributes.color) > PlayGame.cards.colors.indexOf(highestCard.cardAttributes.color)) { // suit rank is higher
              highestCard = card;
            }
            // TODO else, and this can only happen when there is more than one deck in a game since each card is unique per deck
            // let's handle this when we get there, but maybe we just call a tie for f's sake and we don't always have a winner. :) :)
          }
        }
      }
      else {
        highestCard = card;
      }
    }
    return highestCard;
  }


  resetGame() {
    // quick and easy way by restarting scene?
    // or ...
    this.playerMustDraw = true;
    // this.backToPlayGameStartButton.setVisible(true);
    this.showAllPlayButtons(false);
    // this.leaveCurrentGameBtn.disable(true);
    // this.bonuses.container.setVisible(false);
    this.playerInfo.scoreText.text = 0;
    this.gameDeckReplenishCount = 0;
    this.gameFinalTurns = null;
    this.turnTimeLimitProgressBar.setVisible(false);
    // this.bonuses.oneAttributeMatch = { playerId: -1, count: 0 , icon: 'icon-1attribMatchBonus'};
    Object.values(this.seats).forEach((seat) => {
      seat.highlightSeat(false);
      seat.turnTimeLimitProgressBar.setVisible(false);
      seat.hand.moveAllCardsToCardSet({ toCardSet: this.deck, delay: 0, moveArguments: { faceUp: false },
        postCall: ()=>{
          // console.log(`Cleaned seat:`, seat);
      }})
    });
    this.explainEmptyDrawPileBtn.setVisible(false);
    this.discardPile.moveAllCardsToCardSet({ toCardSet: this.deck, delay: 0, moveArguments: { faceUp: false } });
    this.setupReadyButton();
    Object.values(this.seats).forEach((seat) => {
      seat.showCheckMark(true); // Hide all the check marks for players
      seat.checkMark(false);
      seat.setReadyPosition(); // move the seat/player objects towards the edges
    })
  } 

}
