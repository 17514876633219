import Config from '../classes/Config';
import PlayGame from './PlayGame';
import GameButton from '../classes/GameButton';
import PopInText from '../classes/PopInText';
import _ from 'lodash';
import { Dialog } from '@capacitor/dialog';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
export default class Settings extends Phaser.Scene {
  constructor() {
    super({
      key: 'Settings'
    });
  }

  selectedBorderColor = '0xffca3a'

  panels = {}
  buttons = {}

  getWidth() {
    return this.cameras.main.width;
  }

  getHeight() {
    return this.cameras.main.height;
  }

  preload() {}

  create() { // beginning of game where everything is populated
    let self = this;
    self.game.dnrConfig = new Config();
    // background graphic
    let background = new Phaser.GameObjects.Graphics(this);
    background.fillStyle(0x00a6b1, 1); // 0x5c3709 // 0x3e1baf
    background.fillRoundedRect(0, 0, this.getWidth(), this.getHeight(), 3);
    background.setAlpha(.9);
    // background.lineStyle(1, 0x4682B4, 1);
    this.add.existing(background);

    // set interactive on the background so you can't click on the game scene behind this one (this might fix it)
    background.setInteractive(new Phaser.Geom.Rectangle(0,0,this.getWidth(),this.getHeight()), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{});


    ////// buttons

    let xChain = 50;
    let yChain = 50;
    let font = "35px Arial";
    this.buttons.general = new GameButton({scene: this,
      x: xChain,
      y: yChain,
      w: 240,
      h: 90,
      depth: this.depth + 1,
      text: `General`,
      font: font,
      radius: 22,
      clickFunction: ()=>{
        // load general view
        this.switchToPanel(['difficulty', 'audio', 'learning mode']); //, 'game speed']);
        this.buttons.general.select();
      },
      clickFunctionData: {},
      borderWidth: 4,
    });
    this.buttons.general.select();

    xChain += 280;
    this.buttons.avatar = new GameButton({scene: this,
      x: xChain,
      y: yChain,
      w: 240,
      h: 90,
      depth: this.depth + 1,
      text: `Avatar/Name`,
      font: font,
      radius: 22,
      clickFunction: ()=>{
        // load avatar view
        this.switchToPanel(['avatar', 'userName']);
        this.buttons.avatar.select();
      },
      clickFunctionData: {},
      borderWidth: 4,
    });

    xChain += 280;
    this.buttons.theme = new GameButton({scene: this,
      x: xChain,
      y: yChain,
      w: 240,
      h: 90,
      depth: this.depth + 1,
      text: `Theme/Style`,
      font: font,
      radius: 22,
      clickFunction: ()=>{
        // load theme view
        this.switchToPanel(['theme']);
        this.buttons.theme.select();
      },
      clickFunctionData: {},
      borderWidth: 4,
    });

    xChain += 280;
    this.buttons.deck = new GameButton({scene: this,
      x: xChain,
      y: yChain,
      w: 240,
      h: 90,
      depth: this.depth + 1,
      text: `Deck (Cards)`,
      font: font,
      radius: 22,
      clickFunction: ()=>{
        // load deck view
        this.input.topOnly = false;
        this.switchToPanel(['deck']);
        this.buttons.deck.select();
      },
      clickFunctionData: {},
      borderWidth: 4,
    });

    this.buttons.close = new GameButton({scene: this,
      x: this.getWidth() - 70,
      y: yChain,
      w: 50,
      h: 50,
      depth: this.depth + 1,
      text: `✖`,
      font: "40px Arial",
      radius: 20,
      clickFunction: ()=>{
        this.input.topOnly = true;
        this.loadingMessage && this.loadingMessage.destroy();
        this.scene.pause('Settings');
        this.scene.setVisible(false, 'Settings');
        this.scene.stop('Settings');//.remove('Settings');
      },
      clickFunctionData: {},
      borderWidth: 4,
    });

    ////// audio
    const audioWidth = this.getWidth() / 2 - 100;
    this.createTable({
      scene: this, data: PlayGame.gameAudio, key: 'audio',  height: 350, textKey: 'mode', fileKey: 'file', loadCallback: this.audioLoadCallback,
      clickCallback: this.audioClickCallback, showSlider: false, width: audioWidth, showTitle: true
    });
    ////// learning mode
    this.createTable({
      scene: this, data: PlayGame.gameLearningMode, key: 'learning mode',  height: 350, textKey: 'mode', fileKey: 'file', loadCallback: this.learningModeLoadCallback,
      clickCallback: this.learningModeClickCallback, showSlider: false, width: this.getWidth() / 3 - 50, x: audioWidth + 10, showTitle: true
    });

    ////// gameSpeed
    // this.createTable({
    //   scene: this, data: [], key: 'game speed',  height: 350, loadCallback: ()=>{},
    //   clickCallback: this.gameSpeedClickCallback, showSlider: false, width: 350, showTitle: true, x: this.getWidth() * .5,
    //   singleValue: this.scene.get('PlayGame').game.gameSpeed.name
    // });

    ////// avatar
    this.createTable({
      scene: this, data: PlayGame.computerPlayers, key: 'avatar', rows: 2, height: 300, textKey: 'avatar', fileKey: 'avatar', showTextLabel: false, loadCallback: this.avatarLoadCallback,
      clickCallback: this.avatarClickCallback, showSlider: false, width: this.getWidth() - 100
    });
    this.panels.avatar.setVisible(false);
    this.panels.userName = new GameButton({scene: this,
      x: this.getWidth() / 2 - 200,
      y: 480,
      w: 400,
      h: 65,
      depth: this.depth + 1,
      text: this.game.gamePlayerName,
      font: "35px Arial",
      radius: 12,
      clickFunction: async ()=>{
        // Keyboard.show(); // this does not work on ios :(
          const { value, cancelled } = await Dialog.prompt({
            title: 'Display Name',
            message: `Enter your name that others see:`,
            inputText: this.game.gamePlayerName
          });
          // console.log('newName', value, 'cancelled', cancelled)
          this.panels.userName.text.setText('Saving...');
          const returnedName = await this.game.updateDisplayName(value);
          this.panels.userName.text.setText(returnedName);
      },
      clickFunctionData: {},
      borderWidth: 2,
      labelText: "Display Name"
    });

    // not working below
    // var inputText = this.add.rexInputText(0, 0, 100, 60, {
    //   type: 'textarea',
    //   text: 'Guest',
    //   fontSize: '18px',
    // }).setOrigin(0.5);
    // this.add.container(50, 1000, [inputText]);

   
    ////// theme
    this.createTable({
      scene: this, data: PlayGame.arrayOfThemes(), key: 'theme',  height: 350, textKey: 'name', fileKey: 'key', showTextLabel: false, loadCallback: this.themeLoadCallback,
      clickCallback: this.themeClickCallback, showSlider: false, width: this.getWidth() - 100, scaleIconsTo: .15
    });
    this.panels.theme.setVisible(false);

    ////// deck
    this.createTable({
      scene: this, data: PlayGame.arrayOfDeckFaces(), key: 'deck', rows: 1, scrollMode: 1,  height: 350, textKey: 'name', fileKey: 'key', showTextLabel: false, loadCallback: this.deckLoadCallback,
      clickCallback: this.deckClickCallback, showSlider: false, width: this.getWidth() - 100, scaleIconsTo: .55
    });
    this.panels.deck.setVisible(false);
    this.switchToPanel(['avatar', 'userName']);
    this.buttons.avatar.select();
  }

  switchToPanel(keys) {
    for (const k in this.panels) {
      if (keys.indexOf(k) > -1) {
        this.panels[k].setVisible(true);
      } else {
        // console.log('switchToPanel hiding:', k)
        this.panels[k].setVisible(false);
      }
    };
    for (const k in this.buttons) {
      if (keys.indexOf(k) > -1) {
      } else {
        // console.log('switchToPanel hiding:', k)
        this.buttons[k] && this.buttons[k].unselect();
      }
    };
  }

  ////// difficulty
  difficultyLoadCallback(scene, label) {
    if (label.name == this.game.gameDifficulty.level) {
      this.selectDifficultyChild(label);
    }
  }
  difficultyClickCallback(child) {
    this.game.dnrConfig.set("gameDifficulty", child.name);
    this.game.setGameDifficulty(child.name);
    this.selectDifficultyChild(child);
  }
  selectDifficultyChild(child) {
    if (this.selectedDifficulty && this.selectedDifficulty.getElement("background")) {
      this.selectedDifficulty.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedDifficulty = child;
    // this.panels['difficulty'].scrollToChild(child); // not working?
  }

  ////// audio
  audioLoadCallback(scene, label) {
    if (label.name == this.game.gameAudio.mode) {
      this.selectAudioChild(label);
    }
  }
  audioClickCallback(child) {
    this.game.dnrConfig.set("gameAudio", child.name);
    this.game.gameAudio = child.name;
    this.game.setGameAudio(child.name);
    this.selectAudioChild(child);
  }
  selectAudioChild(child) {
    if (this.selectedAudio && this.selectedAudio.getElement("background")) {
      this.selectedAudio.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedAudio = child;
    // this.panels['Audio'].scrollToChild(child); // not working?
  }

  ////// learning mode
  learningModeLoadCallback(scene, label) {
    if (label.name == this.game.gameLearningMode.mode) {
      this.selectLearningModeChild(label);
    }
  }
  learningModeClickCallback(child) {
    this.game.dnrConfig.set("gameLearningMode", child.name);
    this.game.gameLearningMode = child.name;
    this.game.setGameLearningMode(child.name);
    this.selectLearningModeChild(child);
  }
  selectLearningModeChild(child) {
    if (this.selectedLearningMode && this.selectedLearningMode.getElement("background")) {
      this.selectedLearningMode.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedLearningMode = child;
    // this.panels['LearningMode'].scrollToChild(child); // not working?
  }

  ////// avatar
  avatarLoadCallback(scene, label) {
    if (label.name == this.game.humanPlayer.avatar) {
      this.selectAvatarChild(label);
    }
  }
  avatarClickCallback(child) {
    this.game.dnrConfig.set("gameAvatar", child.name);
    this.game.gameAvatar = child.name;
    this.game.setHumanPlayer(child.name);
    this.game.saveHumanPlayerAvatarToUDS(child.name);
    this.selectAvatarChild(child);
  }
  selectAvatarChild(child) {
    if (this.selectedAvatar && this.selectedAvatar.getElement("background")) {
      this.selectedAvatar.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedAvatar = child;
    // this.panels['avatar'].scrollToChild(child); // not working?
  }

  ////// theme
  themeLoadCallback(scene, label) {
    if (label.name == this.game.gameTheme.name) {
      this.selectThemeChild(label);
    }
  }
  themeClickCallback(child) {
    this.game.dnrConfig.set("gameTheme", child.name);
    this.game.setGameTheme(child.name);
    this.selectThemeChild(child);
  }
  selectThemeChild(child) {
    if (this.selectedTheme && this.selectedTheme.getElement("background")) {
      this.selectedTheme.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedTheme = child;
    // this.subPanels['theme'].scrollToChild(child); // not working?
  }

  ////// deck
  deckLoadCallback(scene, label) {
    if (label.name == this.game.gameDeckFace) {
      this.selectDeckChild(label);
    }
  }
  deckClickCallback(child) {
    if ((this.loadingMessage && !this.loadingMessage.visible) || !this.loadingMessage) {
      this.loadingMessage = new PopInText({
        scene: this,
        x: this.getWidth() / 2,
        y: this.getHeight() / 2,
        text: 'Loading Deck...',
        box: {w: 600, h: 300, radius: 30},
        startScale: 1,
        endScale: 1.15,
        scaleBy: 1.15,
        duration: 50,
        onComplete: ()=>{
          //
        },
        font: "56px Arial Black",
      });
      this.load.once(Phaser.Loader.Events.COMPLETE, () => {
        this.game.dnrConfig.set("gameDeckFace", child.name);
        this.game.setGameDeckFace(this.game.scene.keys.PlayGame, child.name);
        this.game.setGameDeckBack(this.game.scene.keys.PlayGame, 'main');
        this.selectDeckChild(child);
        this.loadingMessage.destroy();
      });
      const deck = child.name;
      this.load.image(`${deck}-back-main`, `assets/deck/${deck}/back-main.png`);
      this.load.image(`${deck}-back-alt`, `assets/deck/${deck}/back-alt.png`);
      // this.load.image(`${deck}-icon`, `assets/deck/${deck}/icon.png`);
      PlayGame.cards.colors.forEach( (color) => {
        PlayGame.cards.numbers.forEach( (rank) => {
          PlayGame.cards.suits.forEach( (suit) => {
            this.load.image(`${deck}-`+color+rank+suit, `assets/deck/${deck}/`+color+rank+suit+'.png');
          })
        })
      })
      this.load.start();
    }
  }
  selectDeckChild(child) {
    if (this.selectedDeck && this.selectedDeck.getElement("background")) {
      this.selectedDeck.getElement("background").setFillStyle(0xffffff);
    }
    child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    this.selectedDeck = child;
    // this.subPanels['deck'].scrollToChild(child); // not working?
  }

  ////// GameSpeeds
  // gameSpeedClickCallback(child) {
  //   // console.log(`DR:`, child);
  //   // below is playing around with action sheet (list-style of options. moto phone only gave me 5 max though)
  //   // https://capacitorjs.com/docs/apis/action-sheet
  //   const options = Object.keys(PlayGame.gameSpeeds).map((key) => ({title: key}));
  //   ((async () => await ActionSheet.showActions({
  //     title: 'Select game speed',
  //     options: options,
  //   }))().then((v)=>{
  //     // console.log(v);
  //     const val = options[v.index].title;
  //     this.game.dnrConfig.set("gameSpeed", val);
  //     if (Number(val)) { // if it's an actual number
  //       this.gameSpeedTextObj.style.setFontSize(110);
  //     }
  //     else {
  //       this.gameSpeedTextObj.style.setFontSize(70);
  //     }
  //     this.gameSpeedTextObj.setText(val);
  //     this.game.setGameSpeed(val);
  //   }));
  // }

  ////// shared functions
  createIcon = function (scene, item, textKey, fileKey, loadCallback, scaleIconsTo, showTextLabel) {
    let bgColor = 0xffffff;
    var label = scene.rexUI.add.label({
      background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 14, bgColor),
      orientation: 'y',
      icon: (fileKey) ? scene.add.sprite(0, 0, item[fileKey]).setScale(scaleIconsTo) : '',
      text: (showTextLabel) ? scene.add.text(0, 0, item[textKey], { font: "32px Arial", color: "#000000" }) : null,
      name: item[textKey],
      space: { bottom: 10, icon: 3 },
    });
    label.enableLayoutWarn(false);
    //label.setDepth(10000004);
    loadCallback.call(this, scene, label);
    return label;
  };

  createTable({ scene, data, key, rows = 1, columns = 10, textKey, fileKey, showTextLabel = true, loadCallback, scrollMode = 0, clickCallback, width = 1, height = 330, showTitle = false, showSlider = true, x = 50, y = 160, scaleIconsTo = .5, singleValue = null }) {
    let self = this;
    const keyAsCamel = _.camelCase(key);
    const keyAsPascal = keyAsCamel.charAt(0).toUpperCase() + keyAsCamel.slice(1);
    let scrollablePanel = scene.rexUI.add.scrollablePanel({
      x: x,
      y: y,
      name: key,
      width: width,
      height: height,
      scrollMode: scrollMode,
      background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, 0xffffff),
      panel: {
        child: scene.rexUI.add.fixWidthSizer({
          space: {
            left: 10,
            right: 10,
            top: 15,
            bottom: 3,
            item: 8,
            line: 8,
          }
        }).enableLayoutWarn(false),
        width: width - 100, // was width / 2 which worked as well
        mask: {
          mask: true,
          padding: { right: -15, },
          updateMode: 'everyTick'
        }
      },
      scroller: {},
      slider: showSlider ? {
        track: scene.rexUI.add.roundRectangle(0, 0, 20, 10, 10, PlayGame.COLOR_LIGHT),
        thumb: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, PlayGame.COLOR_GOLD),
      } : {},
      space: {
        left: 10,
        right: 10,
        top: 8,
        bottom: 8,
        panel: 8,
      }
    }).enableLayoutWarn(false).setOrigin(0).layout();
    this.panels[key] = scrollablePanel;
    var items = data;
    //var columns = Math.ceil(items.length / rows);
    // TODO look at moving this to another function so we can re-use it more easily to update a specific panel such as when changing face card we can update face back options
    var table = scene.rexUI.add.gridSizer({
      column: columns,
      row: rows,
      rowProportions: 1,
      space: { column: 15, row: 15 },
      name: key  // Search this name to get table back
    }).enableLayoutWarn(false);//.setDepth(10000003).enableLayoutWarn(false);
    // table.setChildrenInteractive().on('child.click', clickCallback, this);
    var item, r, c;
    if (singleValue) {
      scrollablePanel.setChildrenInteractive().on('child.click', clickCallback, this);
      let bgColor = 0xffffff;
      if (singleValue < 1) {
        singleValue = PlayGame.BASE_VALUES[singleValue];
      }
      let fontSize = 110;
      if (!Number(singleValue)) { // if it's not a number
        fontSize = 70;
      }
      var label = scene.rexUI.add.label({
        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 14, bgColor),
        orientation: 'y',
        icon: '',
        text: scene.add.text(0, 0, singleValue, { font: `${fontSize}px Arial`, color: "#000000" }),
        name: `number${keyAsPascal}`,
        space: { 
          left: 80,
          right: 1,
          top: 70,
          bottom: 50,},
      });
      table.add(label, 0, 0, 'top', 0, true);
      this[`${keyAsCamel}TextObj`] = label.children[1];
    }
    else {
      table.setChildrenInteractive().on('child.click', clickCallback, this);
      for (var i = 0, cnt = items.length; i < cnt; i++) {
        item = items[i];
        r = i % rows;
        c = (i - r) / rows;
        table.add(
          this.createIcon(scene, item, textKey, fileKey, loadCallback, scaleIconsTo, showTextLabel),
          c,
          r,
          'top',
          0,
          true
        );
      }
    }
    scrollablePanel.getElement('panel').addBackground(
      scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, undefined)
    );
    if (showTitle) {
      var capKey = key.charAt(0).toUpperCase() + key.slice(1);
      var title = scene.rexUI.add.label({
        width: width - 100,
        orientation: 'x',
        text: scene.add.text(0, 0, capKey, { font: "32px Arial", color: "#000000" }),
      });//.setDepth(10000006);
      scrollablePanel.getElement('panel').add(
          title, // child
          0, // proportion
          'left', // align
          0, // paddingConfig
          true // expand
        );
    }
    scrollablePanel.getElement('panel').add(
      table, // child
      1, // proportion
      'left', // align
      0, // paddingConfig
      true // expand
    );
    scrollablePanel.layout();
    return scrollablePanel;
  }

  update() { }
}
